import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import mediaPlaceholder from "../../../Common/_assets/img/placeholder/photo-placeholder.png";

import scriptPlaceholder from "../../../Common/_assets/img/bg/script-blank.png";
import ReactGA from "react-ga4";

const TitleMediaItem = ({
	mediaItem,
	categorySlug,
	titleSlug,
	mediaSection,
}) => {
	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";

	const location = useLocation();

	const [thumbnail, setThumbnail] = useState(null),
		[description, setDescription] = useState(null),
		[slug, setSlug] = useState(null),
		[title, setTitle] = useState(null);

	useEffect(() => {
		let subscribed = true;
		if (subscribed && mediaItem) {
			// console.log('media',mediaSection,mediaItem.title)
			let slugPrefix =
				"/" + categorySlug + "/" + titleSlug + "/" + mediaSection + "/";
			if (location.pathname.includes("/pga")) {
				if (mediaSection === "press") {
					if (mediaItem.type === "youtube") {
						setThumbnail(s3Url + mediaItem.thumbnail);
						setSlug("/pga" + slugPrefix + mediaItem.slug);
					} else {
						setThumbnail(s3Url + mediaItem.filename);
						setSlug(mediaItem.url);
					}
				} else if (mediaSection === "scripts") {
					setThumbnail(scriptPlaceholder);
					setSlug("/pga" + slugPrefix + mediaItem.slug);
				} else {
					setThumbnail(s3Url + mediaItem.thumbnail);
					setSlug("/pga" + slugPrefix + mediaItem.slug);
				}
			} else if (location.pathname.includes("/ves")) {
				if (mediaSection === "press") {
					if (mediaItem.type === "youtube") {
						setThumbnail(s3Url + mediaItem.thumbnail);
						setSlug("/ves" + slugPrefix + mediaItem.slug);
					} else {
						setThumbnail(s3Url + mediaItem.filename);
						setSlug(mediaItem.url);
					}
				} else if (mediaSection === "scripts") {
					setThumbnail(scriptPlaceholder);
					setSlug("/ves" + slugPrefix + mediaItem.slug);
				} else {
					setThumbnail(s3Url + mediaItem.thumbnail);
					setSlug("/ves" + slugPrefix + mediaItem.slug);
				}
			} else {
				if (mediaSection === "press") {
					if (mediaItem.type === "youtube") {
						setThumbnail(s3Url + mediaItem.thumbnail);
						setSlug(slugPrefix + mediaItem.slug);
					} else {
						setThumbnail(s3Url + mediaItem.filename);
						setSlug(mediaItem.url);
					}
				} else if (mediaSection === "scripts") {
					setThumbnail(scriptPlaceholder);
					setSlug(slugPrefix + mediaItem.slug);
				} else {
					setThumbnail(s3Url + mediaItem.thumbnail);
					setSlug(slugPrefix + mediaItem.slug);
				}
			}

			setDescription(
				mediaItem.description ? truncate(mediaItem.description) : "",
			);
			setTitle(mediaItem.title);
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaItem, categorySlug, titleSlug, mediaSection]);

	const truncate = (input) => {
		if (input.length > 5) {
			return input.substring(0, 130) + "...";
		}
		return input;
	};

	return (
		<div className={"col-12 col-md-4 mb-3"}>
			<div className={"pe-0 pe-md-4"}>
				<a
					href={slug}
					rel="noopener noreferrer"
					className="titledetail__mediaitem d-block"
					onClick={() => {
						ReactGA.event({
							category: "Title Page Nav | " + mediaSection,
							action:
								mediaSection === "videos" ? "Play" : "Click",
							label: titleSlug + " | " + title,
						});
					}}
				>
					<div
						className="titledetail__mediaitem--thumbnail-container"
						style={{
							backgroundImage: 'url("' + thumbnail + '")',
							backgroundSize:
								mediaSection === "press" ||
								mediaSection === "scripts"
									? "contain"
									: "cover",
							backgroundPosition:
								mediaSection === "press"
									? "center bottom"
									: "center center",
							backgroundRepeat: "no-repeat",
						}}
					>
						<img
							src={mediaPlaceholder}
							alt={title}
							className="titledetail__mediaitem--placeholder"
						/>
					</div>
					<div
						className={
							"d-block  mb-1 titledetail__mediaitem--title " +
							(mediaSection === "scripts" ? "text-center" : "")
						}
					>
						{title}
					</div>
					<div
						className="d-block mb-2 titledetail__mediaitem--description"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				</a>
			</div>
		</div>
	);
};

export default TitleMediaItem;
