import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Search from "./Components/Search/Search";
import Page from "./Components/Page/Page";
import Category from "./Components/Category/Category";
import TitleDetail from "./Components/TitleDetail/TitleDetail";
import EventList from "./Components/Event/EventList";
import EventDetail from "./Components/Event/EventDetail";
import UpdateRSVP from "./Components/Page/UpdateRSVP";
import Unsubscribe from "./Components/Page/Unsubscribe";
import ScreenerProjectList from "./Components/Indee/ScreenerProjectList";
import { fetchIndeeProjects, logout } from "./utils/api/helpers/indee";
import LoginModal from "./Components/Indee/LoginModal";
import { isMobile } from "react-device-detect";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Common/_assets/styles/main.scss";
import HeaderNavMobile from "./Components/Header/HeaderNavMobile";

function App() {
	const [showSearch, setShowSearch] = useState(false),
		[authActionFlag, setAuthActionFlag] = useState(false),
		[isLoggedIn, setIsLoggedIn] = useState(false),
		[indeeApiChecked, setIndeeApiChecked] = useState(false),
		[displayPinModal, setDisplayPinModal] = useState(false),
		[activeTitleName, setActiveTitleName] = useState(null),
		[activeProjectKey, setActiveProjectKey] = useState(null),
		[displayScreenerProjectList, setDisplayScreenerProjectList] =
			useState(false),
		[loggingOut, setLoggingOut] = useState(false),
		[authComplete, setAuthComplete] = useState(false),
		[success, setSuccess] = useState(false),
		[mobileNavOpen, setMobileNavOpen] = useState(false);

	const doLogout = () => {
		setLoggingOut(true);
		logout().then((result) => {
			setTimeout(() => {
				setLoggingOut(false);
				setAuthActionFlag(false);
				setDisplayScreenerProjectList(false);
				setActiveProjectKey(null);
				setActiveTitleName(null);
				setIsLoggedIn(false);
				setAuthComplete(false);
				setSuccess(false);
				window.location.reload();
			}, 500);
		});
	};

	useEffect(() => {
		fetchIndeeProjects().then((result) => {
			setIsLoggedIn(!(result.data.error && result.data.message.unauth));
			setIndeeApiChecked(true);
		});
	}, [authActionFlag, setIsLoggedIn]);

	useEffect(() => {
		let subscribed = true;
		if (subscribed) {
			ReactGA.initialize([
				{
					trackingId: "G-S371Q8G4DD",
				},
				{
					trackingId: "DC-4968236",
				},
			]);
			ReactGA.event({
				category: "conversion",
				allow_custom_scripts: true,
				send_to: "DC-4968236/awardfyc/dcmna00+standard",
			});
			ReactGA.send("pageview");
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div id="root" className="d-flex flex-column">
			<main
				id="mainContainer"
				className="main__container container-fluid d-flex flex-column flex-fill align-items-stretch"
			>
				<Header
					setShowSearch={setShowSearch}
					isLoggedIn={isLoggedIn}
					displayPinModal={displayPinModal}
					setDisplayPinModal={setDisplayPinModal}
					setDisplayScreenerProjectList={
						setDisplayScreenerProjectList
					}
					setActiveProjectKey={setActiveProjectKey}
					activeTitleName={activeTitleName}
					setActiveTitleName={setActiveTitleName}
					indeeApiChecked={indeeApiChecked}
					setMobileNavOpen={setMobileNavOpen}
				/>

				<Router>
					<Routes>
						<Route path={"/"} element={<Home />} />
						<Route path={"/pga"} element={<Home />} />
						<Route path={"/ves"} element={<Home />} />
						<Route path={"/events"} element={<EventList />} />
						<Route
							path={"/events/:eventSlug"}
							element={<EventDetail />}
						/>
						<Route path={"/updatersvp/"} element={<UpdateRSVP />} />
						<Route
							path={"/updatersvp/:hash"}
							element={<UpdateRSVP />}
						/>
						<Route
							path={"/unsubscribe/"}
							element={<Unsubscribe />}
						/>
						<Route
							path={"/unsubscribe/:hash"}
							element={<Unsubscribe />}
						/>
						<Route
							path={"/privacy"}
							element={<Page thisPageName="privacy" />}
						/>
						<Route
							path={"/pga/:categorySlug"}
							element={<Category />}
						/>
						<Route
							path={"/ves/:categorySlug"}
							element={<Category />}
						/>
						<Route path={"/:categorySlug"} element={<Category />} />
						<Route
							path={"/:categorySlug/:titleSlug/*"}
							element={
								<TitleDetail
									activeTitleName={activeTitleName}
									setActiveTitleName={setActiveTitleName}
									isLoggedIn={isLoggedIn}
									displayPinModal={displayPinModal}
									setDisplayPinModal={setDisplayPinModal}
									setDisplayScreenerProjectList={
										setDisplayScreenerProjectList
									}
									activeProjectKey={activeProjectKey}
									setActiveProjectKey={setActiveProjectKey}
									indeeApiChecked={indeeApiChecked}
									isMobile={isMobile}
								/>
							}
						/>
						<Route
							path={"/pga/:categorySlug/:titleSlug/*"}
							element={
								<TitleDetail
									activeTitleName={activeTitleName}
									setActiveTitleName={setActiveTitleName}
									isLoggedIn={isLoggedIn}
									displayPinModal={displayPinModal}
									setDisplayPinModal={setDisplayPinModal}
									setDisplayScreenerProjectList={
										setDisplayScreenerProjectList
									}
									activeProjectKey={activeProjectKey}
									setActiveProjectKey={setActiveProjectKey}
									indeeApiChecked={indeeApiChecked}
									isMobile={isMobile}
								/>
							}
						/>
						<Route
							path={"/ves/:categorySlug/:titleSlug/*"}
							element={
								<TitleDetail
									activeTitleName={activeTitleName}
									setActiveTitleName={setActiveTitleName}
									isLoggedIn={isLoggedIn}
									displayPinModal={displayPinModal}
									setDisplayPinModal={setDisplayPinModal}
									setDisplayScreenerProjectList={
										setDisplayScreenerProjectList
									}
									activeProjectKey={activeProjectKey}
									setActiveProjectKey={setActiveProjectKey}
									indeeApiChecked={indeeApiChecked}
									isMobile={isMobile}
								/>
							}
						/>
						= <Route path={"/*"} element={<p>Not Found</p>} />
					</Routes>
				</Router>
			</main>

			{showSearch ? (
				<div className="search__container d-flex align-items-center justify-content-center">
					<Search setShowSearch={setShowSearch} />
				</div>
			) : (
				""
			)}

			<LoginModal
				authActionFlag={authActionFlag}
				setAuthActionFlag={setAuthActionFlag}
				displayPinModal={displayPinModal}
				setDisplayPinModal={setDisplayPinModal}
				setDisplayScreenerProjectList={setDisplayScreenerProjectList}
				authComplete={authComplete}
				setAuthComplete={setAuthComplete}
				success={success}
				setSuccess={setSuccess}
			/>

			<ScreenerProjectList
				activeProjectKey={activeProjectKey}
				activeTitleName={activeTitleName}
				setActiveProjectKey={setActiveProjectKey}
				displayScreenerProjectList={displayScreenerProjectList}
				setDisplayScreenerProjectList={setDisplayScreenerProjectList}
				doLogout={doLogout}
				loggingOut={loggingOut}
				setUnauth={() => setDisplayPinModal(true)}
			/>

			<HeaderNavMobile
				setShowSearch={setShowSearch}
				isLoggedIn={isLoggedIn}
				displayPinModal={displayPinModal}
				setDisplayPinModal={setDisplayPinModal}
				setDisplayScreenerProjectList={setDisplayScreenerProjectList}
				setActiveProjectKey={setActiveProjectKey}
				activeTitleName={activeTitleName}
				setActiveTitleName={setActiveTitleName}
				indeeApiChecked={indeeApiChecked}
				mobileNavOpen={mobileNavOpen}
				setMobileNavOpen={setMobileNavOpen}
			/>
		</div>
	);
}

export default App;
