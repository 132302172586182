import { Button, Form } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import { fetchGuilds } from "../../../utils/api/helpers/guild";
import { createSubscription } from "../../../utils/api/helpers/subscription";

const EventSignUpInline = () => {
	const [email, setEmail] = useState(null),
		[name, setName] = useState(null),
		[guildAffiliation, setGuildAffiliation] = useState(null),
		[success, setSuccess] = useState(false),
		[formSubmitted, setFormSubmitted] = useState(false);

	const [guildList, setGuildList] = useState([]);

	const nameInputRef = useRef();
	const emailInputRef = useRef();
	const guildInputRef = useRef();

	useEffect(() => {
		fetchGuilds().then((result) => setGuildList(result.data));
	}, []);

	const submitForm = (e) => {
		e.preventDefault();
		createSubscription({ name, email, guildId: guildAffiliation }).then(
			(r) => {
				console.log("ERROR", r.data.error || false);
				setFormSubmitted(true);
				if (r.data.error) {
					setSuccess(false);
					const validName = validateName(name);
					const validEmail = validateEmail(email);
					if (!validName) nameInputRef.current.value = "";
					if (!validEmail) emailInputRef.current.value = "";
				} else {
					setSuccess(true);
				}
			},
		);
	};

	const validateName = (name) => {
		const namePattern = /^[a-zA-Z ]+$/;
		return namePattern.test(name);
	};

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	return (
		<div
			className={"my-5 px-3 mx-auto emailsignup-modal-container"}
			style={{
				width: "100%",
				maxWidth: "420px",
			}}
		>
			<Form>
				{!success ? (
					<>
						<Form.Group
							controlId={"NameInput"}
							className={"name-input mb-2"}
						>
							<Form.Control
								value={name}
								ref={nameInputRef}
								pattern="^[a-zA-Z0-9]+$"
								onChange={(e) => setName(e.target.value)}
								placeholder={"Your Name"}
							/>
						</Form.Group>
						{formSubmitted && !nameInputRef.current.value ? (
							<div className={"text-red text-small mb-2"}>
								Please enter your name.
							</div>
						) : null}
						<Form.Group
							controlId={"EmailInput"}
							className={"email-input mb-2"}
						>
							<Form.Control
								value={email}
								ref={emailInputRef}
								onChange={(e) => setEmail(e.target.value)}
								placeholder={"your@email.com"}
							/>
						</Form.Group>
						{formSubmitted && !emailInputRef.current.value ? (
							<div className={"text-red text-small mb-2"}>
								Please enter your valid email address.
							</div>
						) : null}
						<Form.Group
							controlId="GuildInput"
							className={"guild-input mb-2"}
						>
							<Form.Control
								as="select"
								required
								ref={guildInputRef}
								value={guildAffiliation || ""}
								onChange={(e) => {
									setGuildAffiliation(e.target.value);
								}}
							>
								<option value={null}>
									Select Guild Affiliation
								</option>
								{guildList.map((ga, i) => {
									return (
										<option key={i} value={ga.guild_id}>
											{ga.name}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>
						{formSubmitted && !guildInputRef.current.value ? (
							<div className={"text-red text-small mb-2"}>
								Please enter your valid email address.
							</div>
						) : null}
						<div className={"text-center mt-4"}>
							{!success && !formSubmitted ? (
								<div
									className={"d-flex justify-content-center"}
								>
									<Button
										variant="primary"
										type={"submit"}
										className={
											"button__box button__outline"
										}
										onClick={(e) => submitForm(e)}
									>
										{"SUBMIT"}
									</Button>
								</div>
							) : null}
							{!success && formSubmitted ? (
								<Button
									variant="primary"
									className={"button__box button__outline"}
									onClick={(e) => {
										setFormSubmitted(false);
										setSuccess(false);
										submitForm(e);
									}}
								>
									{"Try Again"}
								</Button>
							) : null}
						</div>
					</>
				) : (
					<p style={{ textAlign: "center", color: "#fff" }}>
						Thank you! Please check your inbox.
					</p>
				)}
			</Form>
		</div>
	);
};

export default EventSignUpInline;
