import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import YouTubeFull from "./Video/YouTubeFull";
import TitleMediaMP4 from "./Video/TitleMediaMP4";
import scriptPlaceholder from "../../../Common/_assets/img/bg/script-blank.png";
import { getShowMediaBySlug } from "../../../utils/api/helpers/Media";
import { getShowVideoBySlug } from "../../../utils/api/helpers/video";

const TitleMediaSingle = ({ setInSubRoute }) => {
	const { titleSlug, categorySlug, mediaSection, mediaSlug } = useParams();

	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";

	const [type, setType] = useState(null),
		[filename, setFilename] = useState(null),
		[thumbnail, setThumbnail] = useState(null),
		[title, setTitle] = useState(null),
		[description, setDescription] = useState(null),
		[imageList, setImageList] = useState(null),
		[nextImageLink, setNextImageLink] = useState(null),
		[prevImageLink, setPrevImageLink] = useState(null),
		[currentIndex, setCurrentIndex] = useState(null),
		[totalImages, setTotalImages] = useState(null),
		[isFysee, setIsFysee] = useState(false),
		[crafts, setCrafts] = useState(null),
		[playlists, setPlaylists] = useState(null);

	const uniqueArr = (arr) => {
		return arr.filter(
			(v, i, a) =>
				a.findIndex(
					(v2) => JSON.stringify(v) === JSON.stringify(v2),
				) === i,
		);
	};

	useEffect(() => {
		setImageList(null);
		setTotalImages(0);
		getShowMediaBySlug(titleSlug, mediaSlug).then((result) => {
			// console.log("Media by slug", mediaSlug, result)
			if (result.data && result.status === 200 && result.data.filename) {
				setType(result.data.type);
				setFilename(
					result.data.type === "youtube"
						? result.data.filename
						: s3Url + result.data.filename,
				);
				setTitle(result.data.title);
				setDescription(result.data.description);
				setThumbnail(
					result.data.thumbnail
						? s3Url + result.data.thumbnail
						: null,
				);
				toTop();
			} else {
				getShowVideoBySlug(titleSlug, mediaSlug).then((result) => {
					if (result.data && result.status === 200) {
						// console.log("Video by slug", mediaSlug, result)
						setType("youtube");
						setIsFysee(true);
						setFilename(result.data.video_key);
						setTitle(result.data.title);
						setDescription(result.data.description);
						setThumbnail(s3Url + result.data.thumbnail.filename);
						setCrafts(uniqueArr(result.data.crafts));
						setPlaylists(uniqueArr(result.data.playlists));
						toTop();
					}
				});
			}
		});
	}, [titleSlug, mediaSlug]);

	useEffect(() => {
		let subscribed = true;
		if (subscribed && mediaSection) {
			// console.log(mediaSection)
			setInSubRoute(true);
		}
		return () => (subscribed = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaSection]);

	useEffect(() => {
		let subscribed = true;
		if (subscribed && imageList) {
			imageList.forEach((o, i) => {
				if (o.media_slug === mediaSlug) {
					setCurrentIndex(i + 1);
					i === 0
						? setPrevImageLink(null)
						: setPrevImageLink(
								"/" +
									categorySlug +
									"/" +
									titleSlug +
									"/" +
									mediaSection +
									"/" +
									imageList[i - 1].media_slug,
							);
					i === Object.keys(imageList).length - 1
						? setNextImageLink(null)
						: setNextImageLink(
								"/" +
									categorySlug +
									"/" +
									titleSlug +
									"/" +
									mediaSection +
									"/" +
									imageList[i + 1].media_slug,
							);
				}
			});
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageList]);

	const toTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<>
			<div className="h-100">
				<div
					className="h-100 justify-content-center titledetail__content"
					style={{ position: "relative", zIndex: "22" }}
				>
					<div className="h-100 col-12 main__content">
						<div className="h-100 title__container  ">
							<div
								className={
									"h-100 titledetail__content--inner d-flex flex-wrap flex-md-nowrap flex-column-reverse flex-md-row w-100 no-gutters position-relative  " +
									(mediaSection !== "scripts"
										? "align-items-center"
										: "align-items-start")
								}
							>
								<>
									{filename && type && type === "youtube" ? (
										<div
											id="youtube"
											className="col-12 position-relative"
											style={{ marginTop: "2rem" }}
										>
											<YouTubeFull
												id={filename}
												mediaPosterUrl={thumbnail}
												title={title}
												description={description}
												isFysee={isFysee}
												crafts={crafts}
												playlists={playlists}
											/>
										</div>
									) : (
										""
									)}

									{type && type === "video/mp4" ? (
										<div
											className="col-12 position-relative"
											style={{ marginTop: "5rem" }}
										>
											<TitleMediaMP4
												mediaUrl={filename}
												mediaPosterUrl={thumbnail}
											/>
											<div className="col-12 titledetail__mediaalbum--title text-left mt-3">
												{title}
											</div>
											<div
												className="col-12 titledetail__mediaalbum--description text-left"
												style={{
													marginBottom: "150px",
												}}
											>
												{description}
											</div>
											{/*<div style={{height:'6rem'}}/>*/}
										</div>
									) : (
										""
									)}

									{type &&
									(type === "image/jpeg" ||
										type === "image/jpg" ||
										type === "image/png") ? (
										<div
											className="col-12 position-relative"
											style={{ marginTop: "5rem" }}
										>
											{prevImageLink ? (
												<a
													href={prevImageLink}
													className=""
													style={{
														position: "absolute",
														top: "50%",
														transform:
															"translateY(-50%)",
														left: "-20px",
														cursor: "pointer",
														color: "#fff",
													}}
												>
													<svg
														viewBox="0 0 24 24"
														width="32"
														height="32"
														stroke="currentColor"
														strokeWidth="2"
														fill="none"
														strokeLinecap="round"
														strokeLinejoin="round"
														className=""
													>
														<line
															x1="19"
															y1="12"
															x2="5"
															y2="12"
														></line>
														<polyline points="12 19 5 12 12 5"></polyline>
													</svg>
												</a>
											) : (
												""
											)}

											<img
												style={{
													maxHeight: "70vh",
													width: "auto",
													maxWidth: "90%",
												}}
												src={filename}
												alt={title}
												className="d-block mx-auto"
											/>

											{currentIndex ? (
												<div
													className="mx-auto p-3 text-center text-uppercase"
													style={{
														fontSize: ".8rem",
													}}
												>
													{currentIndex} of{" "}
													{totalImages}
												</div>
											) : (
												""
											)}

											{nextImageLink ? (
												<a
													href={nextImageLink}
													className=""
													style={{
														position: "absolute",
														top: "50%",
														transform:
															"translateY(-50%)",
														right: "-20px",
														cursor: "pointer",
														color: "#fff",
													}}
												>
													<svg
														viewBox="0 0 24 24"
														width="32"
														height="32"
														stroke="currentColor"
														strokeWidth="2"
														fill="none"
														strokeLinecap="round"
														strokeLinejoin="round"
														className=""
													>
														<line
															x1="5"
															y1="12"
															x2="19"
															y2="12"
														></line>
														<polyline points="12 5 19 12 12 19"></polyline>
													</svg>
												</a>
											) : (
												""
											)}
										</div>
									) : (
										""
									)}

									{type && type === "application/pdf" ? (
										<div className="pdf-embed-wrapper col-12 position-relative text-center px-3 px-xl-0">
											<iframe
												title={title}
												style={{
													position: "relative",
													zIndex: 1,
													minHeight: "500px",
												}}
												type="application/pdf"
												className="d-none d-md-block h-100 titledetail__mediaalbum--script"
												width="100%"
												height="100%"
												frameBorder="no"
												src={
													filename +
													"#zoom=FitW&toolbar=0&navpanes=0"
												}
											></iframe>

											<div
												className="d-block d-md-none"
												style={{
													position: "absolute",
													zIndex: 0,
													top: "50%",
													left: "50%",
													transform:
														"translate(-50%,-50%)",
												}}
											>
												<div className={"py-5"}>
													<a
														href={filename}
														target="_blank"
														rel="noopener noreferrer"
														className={
															"d-flex flex-column"
														}
													>
														<img
															src={
																scriptPlaceholder
															}
															alt={title}
															className={
																"my-3 mx-auto"
															}
															style={{
																maxWidth:
																	"80px",
															}}
														/>
														<div
															className={
																"mb-3 text-small text-white"
															}
															style={{
																lineHeight:
																	"1.3em",
															}}
														>
															Embedded PDFs are
															not supported by
															this browser. Please
															tap to view{" "}
															<span
																className={
																	"fw-bold"
																}
															>
																{title}
															</span>
														</div>
														<button className="button__box justify-content-center">
															View {title}
														</button>
													</a>
												</div>
											</div>
										</div>
									) : (
										""
									)}
								</>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default TitleMediaSingle;
