import React from "react";
import { Row, Col } from "react-bootstrap";

const FooterSmall = (props) => {
	return (
		<div className=" footer__container--small-outer">
			<footer className="footer__container footer__container--small mt-auto py-2">
				<Row
					className="footer__content width_ctrl justify-content-center"
					nogutters="true"
				>
					<Col
						className=" footer__copyright d-flex justify-content-center align-items-center"
						style={{ color: "#999" }}
					>
						<a
							href="https://netflix.com"
							rel="noopener noreferrer"
							target="_blank"
						>
							Netflix.com
						</a>
						<span className="text-muted">
							&nbsp;&nbsp;|&nbsp;&nbsp;
						</span>
						<p>
							All uses of the Netflix For Your Consideration
							Website are subject to the terms of use associated
							with your promotional code and the{" "}
							<a href="/privacy">Privacy Statement</a>
						</p>
					</Col>
				</Row>
			</footer>
		</div>
	);
};

export default FooterSmall;
