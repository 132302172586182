import React, { useEffect, useState } from "react";
import thumbPlaceholder from "../../Common/_assets/img/placeholder/video-placeholder-n.jpg";
import { prepareProjectScreener } from "../../utils/api/helpers/indee";
import ReactGA from "react-ga4";

const ScreenerGridItem = ({
	projectName,
	projectKey,
	scr,
	setOpenScreenerModal,
	setActiveScreener,
	setOverlayImage,
	setOverlayOpacity,
	setOverlayDuration,
	setOverlayPosition,
	setOverlayText,
	setOverlayInterval,
}) => {
	const [title, setTitle] = useState(null),
		[description, setDescription] = useState(null),
		[episodeNumber, setEpisodeNumber] = useState(null);

	const [wmImage, setWmImage] = useState(""),
		[wmOpacity, setWmOpacity] = useState(1),
		[wmDuration, setWmDuration] = useState(0),
		[wmPosition, setWmPosition] = useState(0),
		[wmText, setWmText] = useState(""),
		[wmInterval, setWmInterval] = useState("");

	useEffect(() => {
		let subscribed = true;
		if (subscribed && scr) {
			// console.log('screener grid item',scr)
			setDescription(scr.description ? truncate(scr.description) : "");
			setTitle(scr.name);
			setEpisodeNumber(scr.episode);

			setWmImage(scr.overlay_watermark_details.image_link);
			setWmOpacity(scr.overlay_watermark_details.opacity);
			setWmPosition(scr.overlay_watermark_details.position);
			setWmDuration(scr.overlay_watermark_details.duration_in_seconds);
			setWmInterval(scr.overlay_watermark_details.interval_in_seconds);
			setWmText(scr.overlay_watermark_details.text);
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scr]);

	const truncate = (input) => {
		if (input.length > 5) {
			return input.substring(0, 130) + "...";
		}
		return input;
	};

	const prepareScreener = () => {
		prepareProjectScreener(projectKey, scr.key).then((result) => {
			scr.screening_details.screener_key =
				result.data.screening_details.screener_key;
			setActiveScreener(scr);
			setOpenScreenerModal(true);

			setOverlayImage(wmImage);
			setOverlayOpacity(wmOpacity);
			setOverlayPosition(wmPosition);
			setOverlayDuration(wmDuration);
			setOverlayText(wmText);
			setOverlayInterval(wmInterval);
			console.log(wmInterval);
		});
	};

	return (
		<div
			onClick={() => {
				prepareScreener();
				ReactGA.event({
					category: "Screener Nav",
					action: "View Screener Episode",
					label: episodeNumber
						? projectName +
							" Episode " +
							episodeNumber +
							" - " +
							title
						: projectName + " - " + title,
				});
			}}
			className={
				"episodegrid__item-container titledetail__mediaitem col-12 col-md-4 mb-3"
			}
			style={{ cursor: "pointer" }}
		>
			<img src={scr.poster ? scr.poster : thumbPlaceholder} alt={title} />
			<div className={"episodegrid__item-title"}>
				{episodeNumber ? episodeNumber + " - " + title : projectName}
			</div>
			<div className={"episodegrid__item-description"}>{description}</div>
		</div>
	);
};

export default ScreenerGridItem;
