import React, { useLayoutEffect, useState } from "react";
import { Event, TrackingEvents } from "../../utils/Tracking";
import { useParams, useLocation } from "react-router-dom";

const ReturnToCategory = () => {
	const match = useParams();
	const location = useLocation();

	const selectedType = location.pathname.includes("/pga")
		? "pga"
		: location.pathname.includes("/ves")
			? "ves"
			: "";

	const [selectedCategory, setSelectedCategory] = useState(""),
		[selectedCategorySlug, setSelectedCategorySlug] = useState("");

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed && match.categorySlug) {
			if (match.categorySlug.includes("documentary")) {
				setSelectedCategory("Documentary Series or Special");
				setSelectedCategorySlug("documentary-series-or-special");
			} else if (match.categorySlug.includes("variety")) {
				setSelectedCategory("Variety Programs");
				setSelectedCategorySlug("variety-programs");
			} else if (
				match.categorySlug.includes("reality") ||
				match.categorySlug.includes("competition")
			) {
				setSelectedCategory("Reality Series");
				setSelectedCategorySlug("reality-series");
			} else {
				const category_name = match.categorySlug.split("-").join(" ");
				setSelectedCategory(category_name);
				setSelectedCategorySlug(match.categorySlug);
			}
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match]);

	return (
		<div className="subnav__container d-flex">
			<div className="d-flex align-self-stretch align-items-stretch justify-content-center w-100">
				<a
					onClick={() => {
						Event(
							TrackingEvents.categories.INTERNAL_NAVIGATION,
							TrackingEvents.actions.NAV_CATEGORY_SUBNAV,
							TrackingEvents.labels.NAV_CATEGORY_SUBNAV,
						);
					}}
					className="subnav__container--return d-flex align-items-center"
					href={`/${selectedType ? selectedType + "/" : ""}${selectedCategorySlug}`}
					style={{ textTransform: "capitalize", color: "#f5f5f5" }}
				>
					<svg
						viewBox="0 0 24 24"
						width="18"
						height="18"
						stroke="currentColor"
						strokeWidth="2"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="me-0 me-md-2"
					>
						<line x1="19" y1="12" x2="5" y2="12"></line>
						<polyline points="12 19 5 12 12 5"></polyline>
					</svg>
					<span
						className="d-none d-md-flex"
						style={{ color: "#f5f5f5" }}
					>
						{selectedCategory}
					</span>
				</a>
			</div>
		</div>
	);
};

export default ReturnToCategory;
