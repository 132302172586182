import { Button, Form, Modal } from "react-bootstrap";
import netflixLogo from "../../Common/_assets/img/logo/netflix-logo-ribbon.png";
import { useState, useRef, useEffect } from "react";
import { indeeAuth } from "../../utils/api/helpers/indee";

const LoginModal = ({
	displayPinModal,
	setDisplayPinModal,
	setDisplayScreenerProjectList,
	projectKey,
	authActionFlag,
	setAuthActionFlag,
	authComplete,
	setAuthComplete,
	success,
	setSuccess,
}) => {
	const [pin, setPin] = useState("");

	const inputRef = useRef();

	useEffect(() => {
		clearForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearForm = () => {
		setPin("");
		setSuccess(false);
		setAuthComplete(false);
		setAuthActionFlag(false);
		if (inputRef.current) {
			inputRef.current.value = "";
		}
	};

	const authorize = () => {
		indeeAuth(pin).then((result) => {
			let successful = !(
				(result.data.error && result.data.message.unauth) ||
				result.data.status_code === "W2002"
			);
			setSuccess(successful);
			setAuthComplete(true);
			setAuthActionFlag(!authActionFlag);
			if (successful) {
				setDisplayScreenerProjectList(true);
				setDisplayPinModal(false);
			}
		});
	};

	return (
		<Modal
			centered
			show={displayPinModal}
			onHide={() => {
				clearForm();
				setDisplayPinModal(false);
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title></Modal.Title>
			</Modal.Header>

			<Modal.Body className={"text-center"}>
				<div className={"my-4 pb-2"}>
					<div
						className={
							"d-flex align-items-center justify-content-center"
						}
					>
						<div
							className={
								"d-flex align-items-center justify-content-center pe-3"
							}
						>
							<img
								src={netflixLogo}
								className="header__logo"
								alt="Netflix"
								style={{ maxWidth: "15px" }}
							/>
						</div>
						<div
							className={
								"d-flex align-items-center justify-content-center"
							}
						>
							<span
								className={""}
								style={{
									fontSize: "14px",
									fontWeight: "300",
									color: "#aaa",
								}}
							>
								FYC
							</span>
						</div>
					</div>
				</div>
				<div className={"h4"}>
					{!authComplete
						? "Enter Your Screener Code"
						: success
							? "Success!"
							: "Unauthorized"}
				</div>
				<div className={"p mb-4 pb-2"}>
					{!authComplete
						? "Enter the code you received to access your FYC Screener Content."
						: success
							? "Click below to continue. You may access screeners at any time by clicking\n" +
								'                                    "Watch Now" on any title page.'
							: "There was an issue with the code you entered. Please try again."}
				</div>

				{!authComplete && !success ? (
					<Form.Group controlId={"IndeePin"} className={"pin-input"}>
						<Form.Control
							value={pin}
							ref={inputRef}
							onChange={(e) => setPin(e.target.value)}
							placeholder={"Code"}
						/>
					</Form.Group>
				) : null}
			</Modal.Body>

			<Modal.Footer>
				{!authComplete && !success ? (
					<Button variant="primary" onClick={() => authorize()}>
						{"SUBMIT"}
					</Button>
				) : null}
				{authComplete && !success ? (
					<Button
						variant="primary"
						onClick={() => {
							setAuthComplete(false);
							setPin("");
						}}
					>
						{"ENTER NEW CODE"}
					</Button>
				) : null}
				{authComplete && success && !projectKey ? (
					<Button
						onClick={() => {
							setDisplayPinModal(false);
							setDisplayScreenerProjectList(true);
						}}
					>
						Watch Now
					</Button>
				) : null}
			</Modal.Footer>
		</Modal>
	);
};

export default LoginModal;
