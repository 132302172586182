import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import spacerPortrait from "../../../Common/_assets/img/placeholder/spacer_portrait_series.gif";
import loadingSpinner from "../../../Common/_assets/img/placeholder/spinner.gif";

const TitlePanel = ({
	categories,
	customCategories,
	category_slug,
	show_slug,
	show_title,
	featured,
	show_featured,
	key_art,
}) => {
	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";
	const location = useLocation();

	const selectedType = location.pathname.includes("/pga")
		? "pga"
		: location.pathname.includes("/ves")
			? "ves"
			: "";

	const [defaultImageSrc, setDefaultImageSrc] = useState(loadingSpinner),
		[slug, setSlug] = useState(""),
		[title, setTitle] = useState(""),
		[titleCategories, setTitleCategories] = useState([]),
		[categorySlugForUrl, setCategorySlugForUrl] = useState("");

	useEffect(() => {
		let subscribed = true;
		if (subscribed) {
			setSlug(show_slug);
			setTitle(show_title);

			if (categories) {
				let catParsed = JSON.parse("[" + categories[0] + "]");
				setTitleCategories(catParsed);
			}
			if (customCategories) {
				setTitleCategories(customCategories);
			}

			setDefaultImageSrc(s3Url + key_art);

			if (category_slug.includes("documentary")) {
				setCategorySlugForUrl("documentary-series-or-special");
			} else if (category_slug.includes("variety")) {
				setCategorySlugForUrl("variety-programs");
			} else if (
				category_slug.includes("reality") ||
				category_slug.includes("competition")
			) {
				setCategorySlugForUrl("reality-series");
			} else {
				setCategorySlugForUrl(category_slug);
			}
		}
		return () => (subscribed = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories, category_slug, show_slug, show_title, key_art]);

	return (
		<a
			href={`/${selectedType ? selectedType + "/" : ""}${categorySlugForUrl}/${slug}`}
			id={slug}
			data-title={title}
			target="_self"
			className={
				featured
					? "titleart__panel-container col-12 col-sm"
					: "titleart__panel-container col-4 col-sm-3 col-md-2 "
			}
			title={title}
		>
			<div className="titleart__panel">
				{featured ? (
					<img
						src={spacerPortrait}
						className="spacer spacer__portrait"
						alt={title}
						aria-hidden="true"
					/>
				) : (
					<img
						src={spacerPortrait}
						className="spacer spacer__portrait"
						alt={title}
						aria-hidden="true"
					/>
				)}

				<div className="titleart__panel-content d-flex">
					{defaultImageSrc ? (
						<img
							src={defaultImageSrc}
							className="titleart__image"
							alt={title}
						/>
					) : (
						<img
							src={loadingSpinner}
							className="titleart__image loading__spinner"
							aria-hidden="true"
							alt={title}
						/>
					)}
				</div>
			</div>

			{featured &&
			(category_slug === "documentary-series-or-special" ||
				category_slug === "limited-series-television-movie" ||
				category_slug === "reality-series" ||
				category_slug === "variety-programs") ? (
				<div
					style={{ borderLeft: "2px solid #e50914" }}
					className=" text-white mt-3 ps-1 ps-md-2 font-weight-light text-uppercase text-small"
				>
					{titleCategories
						? titleCategories.map((cat, i) => {
								if (
									cat.slug !== "reality-programs" &&
									cat.slug !==
										"documentary-series-or-special" &&
									cat.slug !==
										"limited-series-television-movie" &&
									cat.slug !== "nonfiction-series" &&
									cat.slug !== "variety-programs" &&
									cat.slug !== "variety-specials" &&
									cat.slug !== "variety-series" &&
									cat.slug !== "reality-series"
								) {
									// console.log(cat,i)
									return (
										<span
											className={"genre-featured-label"}
											key={i}
										>
											{cat.slug ===
											"childrens-animated-programs"
												? "Animated Programs"
												: cat.name}
										</span>
									);
								} else {
									return null;
								}
							})
						: null}
				</div>
			) : null}
		</a>
	);
};

export default TitlePanel;
