import React, { useEffect, useState } from "react";
import TitlePanel from "./TitlePanel";

const TitleList = ({ shows, category_slug, featured }) => {
	const [filteredShowList, setFilteredShowList] = useState(null);

	useEffect(() => {
		if (shows) {
			const filteredShows = shows.filter((s, i) => {
				let sGenres = "[" + s.genres[0] + "]";
				sGenres = JSON.parse(sGenres);
				return sGenres.some((e) => e.slug === category_slug);
			});
			setFilteredShowList(filteredShows);
		}
	}, [category_slug, shows]);

	if (filteredShowList && Object.keys(filteredShowList).length > 0) {
		return (
			<>
				{!featured ? (
					<h1 className="px-5 text-center mb-5 font-weight-bold genre-section-title-custom">
						{category_slug.replace(/-/g, " ")}
					</h1>
				) : null}

				<div className="genre__container width_ctrl">
					<div
						id="genre__grid"
						className={
							"mb-5 genre__tier genre__tier--one d-flex flex-wrap justify-content-center "
						}
					>
						{filteredShowList
							? filteredShowList.map((s, i) => {
									return (
										<TitlePanel
											key={i}
											categories={s.genres}
											category_slug={category_slug}
											featured={featured}
											show_featured={s.featured}
											show_slug={s.slug}
											show_title={s.title}
											key_art={s.keyArt}
										/>
									);
								})
							: null}
					</div>
				</div>
			</>
		);
	} else {
		return "";
	}
};

export default TitleList;
