import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import categoryBackground from "../../Common/_assets/img/bg/bg-streams-24.png";
import TitleList from "./TitleList/TitleList";
import SubNav from "../Subnav/Subnav";
import Footer from "../Footer/Footer";
import { useParams, useLocation } from "react-router-dom";
import {
	getShowGenrePageData,
	getShowGenrePageDataPGA,
	getShowGenrePageDataVES,
} from "../../utils/api/helpers/ShowGenres";

const Category = () => {
	const { categorySlug } = useParams();
	const [shows, setShows] = useState(null),
		[featuredShows, setFeaturedShows] = useState(null);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes("/pga")) {
			getShowGenrePageDataPGA(categorySlug)
				.then((result) => {
					if (result.data && result.status === 200) {
						setFeaturedShows(result.data.shows);
						setShows(null);
					}
				})
				.catch(() => {});
		} else if (location.pathname.includes("/ves")) {
			getShowGenrePageDataVES(categorySlug)
				.then((result) => {
					if (result.data && result.status === 200) {
						setFeaturedShows(result.data.shows);
						setShows(null);
					}
				})
				.catch(() => {});
		} else {
			getShowGenrePageData(categorySlug)
				.then((result) => {
					if (result.data && result.status === 200) {
						setFeaturedShows(result.data.featuredShows);
						setShows(result.data.shows);
					}
				})
				.catch(() => {});
		}
	}, [categorySlug, location]);

	return (
		<>
			<Helmet>
				<title>Netflix FYC - Series</title>
			</Helmet>

			<div className="subnav__container--absolute d-flex align-items-center">
				<SubNav />
			</div>

			<div className="pb-5 px-4 px-md-0" style={{ paddingTop: "95px" }}>
				{categorySlug === "documentary-series-or-special" ? (
					<>
						<TitleList
							shows={featuredShows}
							featured={true}
							category_slug={categorySlug}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"documentary-series"}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"documentary-special"}
						/>
					</>
				) : categorySlug === "limited-series-television-movie" ? (
					<>
						<TitleList
							shows={featuredShows}
							featured={true}
							category_slug={categorySlug}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"limited-series"}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"television-movie"}
						/>
					</>
				) : categorySlug === "reality-series" ? (
					<>
						<TitleList
							shows={featuredShows}
							featured={true}
							category_slug={categorySlug}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"structured-reality-program"}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"unstructured-reality-program"}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"reality-competition-program"}
						/>
					</>
				) : categorySlug === "variety-programs" ? (
					<>
						<TitleList
							shows={featuredShows}
							featured={true}
							category_slug={categorySlug}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"variety-special-pre-recorded"}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={"variety-special-live"}
						/>
						{/*<TitleList*/}
						{/*	shows={shows}*/}
						{/*	featured={false}*/}
						{/*	category_slug={*/}
						{/*		"short-form-comedy-drama-or-variety-series"*/}
						{/*	}*/}
						{/*/>*/}
					</>
				) : (
					<>
						<TitleList
							shows={featuredShows}
							featured={true}
							category_slug={categorySlug}
						/>
						<TitleList
							shows={shows}
							featured={false}
							category_slug={categorySlug}
						/>
					</>
				)}
			</div>

			<div
				className="main__background"
				style={{
					backgroundImage: "url(" + categoryBackground + ")",
					opacity: ".05",
				}}
			/>

			<Footer />
		</>
	);
};

export default Category;
