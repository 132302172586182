import React, { useLayoutEffect, useState } from "react";
import Moment from "react-moment";

const EventExcerptTitlePage = ({ event_object }) => {
	const [startDateTime, setStartDateTime] = useState(null);

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed) {
			let tempDateTime = event_object.start_date.replace("Z", "");
			setStartDateTime(tempDateTime);
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event_object]);

	return (
		<>
			{event_object ? (
				<div className="col-12 col-md-6">
					<a href={"/events/" + event_object.slug}>
						<div className="titledetail__event--date d-flex align-items-center justify-content-center">
							<div
								style={{
									backgroundColor: "rgba(255,255,255, 0.05)",
								}}
								className="col-3 p-3 d-flex flex-column align-items-center justify-content-center"
							>
								<div className="titledetail__event--date-day">
									<Moment format="dddd">
										{startDateTime}
									</Moment>
								</div>
								<div className="titledetail__event--date-number">
									<Moment format="DD">{startDateTime}</Moment>
								</div>
								<div className="titledetail__event--date-month">
									<Moment format="MMM">
										{startDateTime}
									</Moment>
								</div>
							</div>

							<div className="px-3 col-9">
								<div
									className="d-block text-white mb-2"
									style={{ fontSize: "1.3rem" }}
								>
									{event_object.title}
								</div>
							</div>
						</div>
					</a>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default EventExcerptTitlePage;
