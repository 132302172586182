import React from "react";
import Footer from "../Footer/Footer";

const ContentPage = ({ thisPageName }) => {
	if (thisPageName === "privacy") {
		return (
			<>
				<div className="page__container row no-gutters width_ctrl my-5 pt-5">
					<div className="page__content page__content-privacy px-3">
						<h3>Terms of Use and Privacy Statement</h3>

						<div>
							<p className="c4 c30 c51">
								<span className="c25 c14"></span>
							</p>
						</div>
						<p className="c2">
							<span className="c33 c16 c14"></span>
						</p>
						<p className="c19">
							<span className="c0">&nbsp;</span>
						</p>
						<p className="c19">
							<span className="c6 c9">Welcome to </span>
							<span className="c6">
								the Netflix &ldquo;For Your Consideration&rdquo;
								website (&ldquo;Website
							</span>
							<span className="c6 c9">
								&rdquo;)! This Terms of Use and Privacy
								Statement document (&ldquo;Terms&rdquo;)
								explains our Terms of Use and Privacy Statement
								for this Website.
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c0">
								You must be at least 18 years of age to interact
								with this Website. The Website, its contents,
								and its services (the &ldquo;Website&rdquo;) are
								for entertainment purposes only. &nbsp;This
								Website is brought to you by the following
								Netflix entity or entities:
							</span>
						</p>
						<p className="c2">
							<span className="c6 c47 c45 c48"></span>
						</p>
						<div className={"my-5 privacy-table"}>
							<div className="divTable">
								<div className="divTableBody">
									<div className="divTableRow">
										<div className="divTableCell fw-bold">
											<p>Country/Region of Residence</p>
										</div>
										<div className="divTableCell fw-bold">
											<p>
												Entity (For more information,
												please visit{" "}
												<a href="https://www.google.com/url?q=http://netflix.com/corporateinfo&amp;sa=D&amp;source=editors&amp;ust=1729804887604598&amp;usg=AOvVaw0MuxNSFEPgKrBpldciQ2Dq">
													http://netflix.com/corporateinfo
												</a>
												.)
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>
												United States, Latin America
												(excluding Brazil and Mexico)
											</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Inc.</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Brazil</p>
										</div>
										<div className="divTableCell">
											<p>
												Netflix Entretenimento Brasil,
												Ltda.
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Canada</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Services Canada ULC</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>India</p>
										</div>
										<div className="divTableCell">
											<p>
												Netflix Entertainment Services
												India LLP
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Japan</p>
										</div>
										<div className="divTableCell">
											<p>Netflix G.K.</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Korea</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Services Korea Ltd.</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>United Kingdom</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Services UK Limited</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>
												Asia-Pacific region (excluding
												Australia, India, Japan, and
												Korea)
											</p>
											<p>&nbsp;</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Pte. Ltd.</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Australia</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Australia Pty Ltd</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>France</p>
										</div>
										<div className="divTableCell">
											<p>
												Netflix Services France S.A.S.
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Germany</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Services Germany GmbH</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Italy</p>
										</div>
										<div className="divTableCell">
											<p>Netflix Services Italy S.R.L.</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Spain</p>
										</div>
										<div className="divTableCell">
											<p>
												Netflix Servicios de
												Transmisi&oacute;n
												Espa&ntilde;a, S.L.
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>Mexico</p>
										</div>
										<div className="divTableCell">
											<p>
												Netflix M&eacute;xico S. de R.L.
												de C.V.
											</p>
										</div>
									</div>
									<div className="divTableRow">
										<div className="divTableCell">
											<p>All other countries</p>
										</div>
										<div className="divTableCell">
											<p>Netflix International B.V.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c6">
								For questions about this Website, please contact
								us by email at{" "}
							</span>
							<span className="c27 c6">
								<a
									className="c12"
									href="mailto:experience@netflix.com"
								>
									experience@netflix.com
								</a>
							</span>
							<span className="c6">
								. For questions about our privacy practices or
								these Terms, please contact us by email at{" "}
							</span>
							<span className="c27 c6">
								<a
									className="c12"
									href="mailto:privacy-site@netflix.com"
								>
									privacy-site@netflix.com
								</a>
							</span>
							<span className="c6">. </span>
							<span className="c10 c14">
								Please include the name of the Website if you
								contact us.
							</span>
						</p>
						<p className="c2">
							<span className="c10 c14"></span>
						</p>
						<p className="c19">
							<span className="c6 c7 c9">To see our </span>
							<span className="c27 c6 c9">
								California Consumer Privacy Act (CCPA) Privacy
								Notice
							</span>
							<span className="c6 c7 c9">, including </span>
							<span className="c27 c6 c9">
								Notice at Collection
							</span>
							<span className="c6 c7 c9">
								&nbsp;details, please scroll down to the{" "}
							</span>
							<span className="c27 c6 c9">
								Supplemental Privacy Disclosures for US
								Residents
							</span>
							<span className="c6 c7 c9">&nbsp;section.</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c40">
							<span className="c16 c13">
								Collection and Use of Information
							</span>
						</p>
						<p className="c20">
							<span className="c6 c9">
								Some experiences on the Website permit or
								require you to register for an account. In order
								to provide you with the Website, we and/or our
								Website Providers may collect certain
								information and/or content from you, including
								the following:{" "}
							</span>
						</p>
						<ul className="c1 lst-kix_list_6-1 start">
							<li className="c41 li-bullet-0">
								<span className="c16">Identifiers</span>
								<span className="c6">
									&nbsp;(such as name, e-mail address,
									professional affiiations, postal address,
									postal code, telephone number, IP address,
									payment information, identifiers from the
									devices you use to connect, characteristics
									about the networks you use when you connect
									to our Website)
								</span>
							</li>
							<li className="c41 li-bullet-0">
								<span className="c16">
									Internet or other electronic network
									activity information
								</span>
								<span className="c0">
									&nbsp;(such as your interactions with the
									Website)
								</span>
							</li>
						</ul>
						<p className="c51 c30 c57">
							<span className="c0 c39"></span>
						</p>
						<p className="c20">
							<span className="c6 c9">
								We and our Website Providers will use the
								information and content you provide to
								administer this Website.{" "}
							</span>
						</p>
						<p className="c20">
							<span className="c0">
								This Website may permit you to sign up to
								receive marketing or informational emails. You
								can unsubscribe from such emails at any time by
								clicking on the &ldquo;unsubscribe&rdquo; link
								in the footer, or by following such other
								instructions as may be provided to
								unsubscribe.{" "}
							</span>
						</p>
						<p className="c20">
							<span className="c0 c9">
								When you interact with us, certain information
								might be collected automatically. Examples of
								information include: the type of computer
								operating system, device and software
								characteristics (such as type and
								configuration), referral URLs, IP address (which
								may tell us your general location), statistics
								on page views or interactions with activities,
								and browser and standard web server log
								information. This information is collected using
								technologies such as cookies, pixel tags, and
								web beacons. We use this information for the
								support of internal operations, such as to
								conduct research and analysis to address the
								performance of our Website, and to generate
								aggregated or de-identified reporting for our
								use.{" "}
							</span>
						</p>
						<p className="c20">
							<span className="c0 c9">
								This Website might use cookies to support the
								performance of our site and to remember choices
								you have made, such as preferred language. You
								can modify your browser settings to control
								whether your computer or other device accepts or
								declines cookies. If you choose to decline
								cookies you may not be able to use certain
								interactive features of this Website or certain
								of its Websites. You can delete cookies from
								your browser; however, that means that any
								settings or preferences managed by those cookies
								will also be deleted and you may need to
								recreate them. Depending on your mobile device,
								you may not be able to control tracking
								technologies through settings. The emails we
								send might include a web beacon or similar
								technology that lets us know if you received or
								opened the email and whether you clicked on any
								of the links in the email.{" "}
							</span>
						</p>
						<p className="c20">
							<span className="c6">
								This Website might use Google Analytics, a web
								analytics service offered by Google. Google
								Analytics assists us in gathering analytics and
								statistical data in connection with the Website.
								On our behalf Google processes this information
								to analyze the usage of the Website, create
								reports on the Website activities, and provide
								other services related to Website and internet
								usage for us. If you have any questions or
								concerns with regard to Google Analytics&rsquo;
								privacy practices, you can review their privacy
								policy at{" "}
							</span>
							<span className="c34 c6 c53">
								<a
									className="c12"
									href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1729804887614322&amp;usg=AOvVaw3k-iW6VBjxmh1J2YClmTaM"
								>
									https://policies.google.com/privacy?hl=en
								</a>
							</span>
							<span className="c0">.</span>
						</p>
						<p className="c20">
							<span className="c0">
								If you interact with the Website on the Facebook
								platform, the Website might collect your public
								Facebook profile information, which includes
								your first and last name and your profile
								picture. The Website might also collect other
								information from the Facebook platform; if so
								you will be prompted about what specific
								information will be collected when you connect
								to the Website for the first time.
							</span>
						</p>
						<p className="c19">
							<span className="c0">
								The Website might give you the option to share
								information by email, social or other sharing
								applications, using the clients and applications
								on your smart device. Social plugins (including
								those offered by Facebook, Twitter, Instagram,
								and Pinterest) allow you to share information on
								those platforms. Social plugins and social
								applications are operated by the social network
								themselves and are subject to their terms of use
								and privacy policies which are available on
								their website.
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c40">
							<span className="c0">
								We use reasonable administrative, logical,
								physical and managerial measures to safeguard
								your information against loss, theft and
								unauthorized access, use and modification. We
								may retain information as required or permitted
								by applicable laws and regulations, including to
								fulfill the purposes described in these Terms.
							</span>
						</p>
						<p className="c19">
							<span className="c8 c16 c14">
								Disclosure of Information
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c0">
								We may disclose your information for certain
								purposes and to third parties, as described
								below:
							</span>
						</p>
						<ul className="c1 lst-kix_list_7-0 start">
							<li className="c18 c17 li-bullet-0">
								<span className="c3">
									The Netflix family of companies: We{" "}
								</span>
								<span className="c6">might</span>
								<span className="c3">
									&nbsp;share your information among the
									Netflix family of companies (
								</span>
								<span className="c27 c6">
									<a
										className="c12"
										href="https://www.google.com/url?q=http://netflix.com/corporateinfo&amp;sa=D&amp;source=editors&amp;ust=1729804887615390&amp;usg=AOvVaw0JY17CL6K9wlMY_iXvGca1"
									>
										http://netflix.com/corporateinfo
									</a>
								</span>
								<span className="c3">)</span>
								<span className="c6">&nbsp;</span>
								<span className="c3">
									as needed for data processing and storage,
									providing customer support, content
									development, and for other purposes
									described in the Collection and Use of
									Information Section of this document.{" "}
								</span>
							</li>
							<li className="c40 c17 c43 li-bullet-0">
								<span className="c3">We </span>
								<span className="c6">might</span>
								<span className="c3">
									&nbsp;use other companies, agents or
									contractors (&quot;
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									&nbsp;Providers&quot;) to perform services
									on our behalf or to help us to provide
									this{" "}
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									&nbsp;to you. For example, we may use{" "}
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									&nbsp;Providers to provide infrastructure
									and IT services (like hosting the{" "}
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									). We do not authorize{" "}
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									&nbsp;Providers to use information except in
									connection with providing their services,
									subject to the following safety issues. We
									and our{" "}
								</span>
								<span className="c6">Website</span>
								<span className="c3">
									&nbsp;Providers may disclose and otherwise
									use information where we or they reasonably
									believe such disclosure is needed to (a)
									satisfy any applicable law, regulation,
									legal process, or governmental request, (b)
									enforce these Terms, including investigation
									of potential violations thereof, (c) detect,
									prevent, or otherwise address illegal or
									suspected illegal activities, security or
									technical issues, or (d) protect against
									harm to the rights, property or safety of
									Netflix, our content partners, users or the
									public, as required or permitted by law.
								</span>
							</li>
						</ul>
						<p className="c19">
							<span className="c6">
								If, in the course of sharing information, we
								transfer personal information to countries
								outside your region, we will take steps to
								ensure that the information is transferred in
								accordance with these Terms and in accordance
								with the applicable laws on data
								protection.{" "}
							</span>
							<span className="c6 c7 c9">
								Specifically, we use a variety of contractual,
								technical, and organizational measures as
								appropriate for such transfers, including data
								protection agreements, technical protections,
								and practices to challenge disproportionate or
								unlawful government authority requests.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c8 c16 c14">
								Your Information and Rights
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c0">
								You can request access to your personal
								information or correct or update out-of-date or
								inaccurate personal information we hold about
								you. You can object to processing of your
								personal information, ask us to restrict
								processing of your personal information, request
								portability of your personal information, or
								request deletion of your personal information
								(subject to exceptions under applicable privacy
								laws). If we have collected and processed your
								personal information with your consent, you can
								withdraw your consent at any time. Withdrawing
								your consent will not affect the lawfulness of
								any processing we conducted prior to your
								withdrawal, nor will it affect processing of
								your personal information conducted in reliance
								on lawful processing grounds other than
								consent.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c6">
								You can assert these rights only where we
								receive a verified request from you. To make
								requests, or if you have any other question
								regarding our privacy practices, please contact
								our Data Protection Officer/Privacy Office
								at{" "}
							</span>
							<span className="c27 c6">
								<a
									className="c12"
									href="mailto:privacy-site@netflix.com"
								>
									privacy-site@netflix.com
								</a>
							</span>
							<span className="c6">. </span>
							<span className="c16">
								Please include the name of the Website if you
								contact us.{" "}
							</span>
							<span className="c0">
								We respond to all requests we receive from
								individuals wishing to exercise their data
								protection rights in accordance with applicable
								data protection laws.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c0">
								You have the right to complain to a data
								protection authority about our collection and
								use of your personal information.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c8 c16 c14">Data Retention</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c0">
								We keep your personal information for no longer
								than is necessary for the purposes we collected
								it for, including to comply with any legal,
								accounting, or reporting requirements. In order
								to determine the retention period, we consider
								the amount, nature, and sensitivity of the
								information, the potential risk of harm from
								unauthorized use or sharing of your personal
								information, the purposes for which we use your
								personal information, whether we can achieve
								those purposes in other ways, and the applicable
								legal requirements. Specifically, we retain your
								personal information in order to honor your
								choices, for our billing or records purposes,
								and to fulfill the purposes described in these
								Terms. We take reasonable measures to destroy or
								de-identify personal information in a secure
								manner when it is no longer required.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c51 c52">
							<span className="c16 c13">
								User Generated Content
							</span>
						</p>
						<p className="c19">
							<span className="c0">
								The Website might allow you to communicate,
								upload, or otherwise make available text,
								images, audio, video, or other content
								(&ldquo;User Generated Content&rdquo; or
								&ldquo;UGC&rdquo;), which might be accessible
								and/or viewable by the public. In submitting
								UCC, you:
							</span>
						</p>
						<ol className="c1 lst-kix_list_1-0 start" start="1">
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									warrant that you hold any and all rights in
									the UGC or, where applicable, obtained third
									party consent for the use of the UGC and are
									entitled to submit the UGC to the Website;
								</span>
							</li>
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									indemnify Netflix against any and all claims
									from third parties that the UGC infringes
									any third party rights;{" "}
								</span>
							</li>
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									represent that the UGC is in compliance with
									applicable laws and regulations and in
									particular not be obscene, defamatory,
									discriminatory, threatening, pornographic,
									libelous, harassing, hateful, racially or
									ethnically offensive or capable of
									encouraging conduct that would be considered
									a criminal offense;{" "}
								</span>
							</li>
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									grant Netflix a worldwide, non-exclusive,
									sub-licensable, royalty-free, irrevocable
									license in respect of all copyright and
									other intellectual property rights in and to
									your UGC to modify, adapt, publish, copy,
									display, repost, communicate to the public,
									or otherwise deal with such UGC as Netflix
									sees fit;{" "}
								</span>
							</li>
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									agree to waive any and all of your portrait
									rights (to the extent allowed under the
									applicable law) and have asked any third
									parties of whom portraits have been used (if
									any) for their consent to use
									portraits/pictures in the UGC; and
								</span>
							</li>
							<li className="c18 c17 li-bullet-0">
								<span className="c0">
									agree to execute all such assignments or
									other documents and take such actions as may
									be reasonably required by Netflix to give
									effect to the licensed and assignments
									contained in this paragraph.
								</span>
							</li>
						</ol>
						<p className="c2">
							<span className="c8 c16 c14 c44"></span>
						</p>
						<p className="c19">
							<span className="c8 c14 c16">
								Name and Likeness
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c0">
								By interacting with this Website, RSVP&rsquo;ing
								and/or attending a Netflix event (the
								&ldquo;Event&rdquo;), you grant the Netflix
								entity that provides you with this Website, its
								affiliates and respective successors and assigns
								and anyone authorized by any of them
								(collectively, &ldquo;Netflix&rdquo;), the
								irrevocable, perpetual, worldwide, non-exclusive
								right to photograph, record, depict, and/or
								portray you and use, and grant to others the
								right, but not the obligation, to photograph,
								record, depict, and/or portray you and use, your
								actual or simulated likeness, name, photograph,
								voice, actions, etc. in connection with the
								development, production, distribution,
								exploitation, advertising, promotion and
								publicity of the Event and/or this Website, in
								all media, now known and later devised,
								worldwide, in perpetuity, in any and all
								languages, formats, versions, and forms related
								to such Website without compensation to you or
								any other individual, unless prohibited by law.
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c19">
							<span className="c8 c16 c14">
								Assumption of Risk &amp; Release of Liability
							</span>
						</p>
						<p className="c2">
							<span className="c8 c16 c14"></span>
						</p>
						<p className="c19">
							<span className="c31 c14">
								NETFLIX EXPERIENCES, EVENTS AND SITES MAY OFFER
								INTERACTIVE OPPORTUNITIES (&ldquo;INTERACTIVE
								OPPORTUNITIES&rdquo;). YOU, BEING AWARE OF YOUR
								OWN HEALTH AND PHYSICAL CONDITION, ACKNOWLEDGE
								THAT YOUR PARTICIPATION IN ANY INTERACTIVE
								OPPORTUNITIES MAY SUBJECT YOU TO CERTAIN RISKS
								SUCH AS PHYSICAL AND/OR PSYCHOLOGICAL INJURY,
								PAIN, SUFFERING, ILLNESS, DISFIGUREMENT,
								TEMPORARY OR PERMANENT DISABILITY, DEATH OR
								ECONOMIC LOSS. BY VOLUNTARILY PARTICIPATING IN
								ANY INTERACTIVE OPPORTUNITIES, YOU AGREE TO
								ASSUME ALL SUCH RISKS OF PARTICIPATING IN THE
								INTERACTIVE OPPORTUNITIES WHETHER KNOWN OR NOT
								KNOWN AND WHETHER ARISING FROM YOUR OWN OR
								OTHERS&rsquo; ACTIONS, INACTIONS, OR NEGLIGENCE,
								OR THE CONDITION OF THE EVENT LOCATION OR
								FACILITIES. YOU HEREBY RELEASE AND DISCHARGE
								NETFLIX, ITS AFFILIATES, SUBSIDIARIES, AGENCIES,
								VENDORS, AND EACH OF THEIR EMPLOYEES FROM ANY
								AND ALL LIABILITIES, CLAIMS, DEMANDS, ACTIONS,
								AND CAUSES OF ACTION WHATSOEVER ARISING OUT OF
								OR RELATED TO ANY LOSS, DAMAGE, ACCIDENT OR
								INJURY, INCLUDING DEATH, NEGLIGENCE, OR INHERENT
								RISK ASSOCIATED WITH OR THAT MAY BE SUSTAINED BY
								YOU, OR TO ANY PROPERTY BELONGING TO YOU, WHILE
								PARTICIPATING IN THE INTERACTIVE OPPORTUNITIES,
								REGARDLESS OF THE CAUSE.
							</span>
						</p>
						<p className="c46 c30">
							<span className="c31 c14"></span>
						</p>
						<p className="c19">
							<span className="c8 c16 c14">
								Intended use of the Website
							</span>
						</p>
						<p className="c2">
							<span className="c3 c8"></span>
						</p>
						<p className="c19">
							<span className="c0">
								This Website and any related content or
								activities are for your personal and
								non-commercial use only. During your use of the
								Website, we grant you a limited, non-exclusive,
								non-transferable, license to access the Website
								content and activities. Except for the foregoing
								limited license, no right, title or interest
								shall be transferred to you. You agree not to
								use the Website for public performances. You are
								responsible for all Internet access charges.
							</span>
						</p>
						<p className="c50" id="h.gjdgxs">
							<span className="c0">
								You agree not to archive, download (other than
								through caching necessary for personal use),
								reproduce, distribute, modify, display, perform,
								publish, license, create derivative works from,
								offer for sale, or use content and information
								contained on or obtained from or through the
								Website without express written permission from
								Netflix and its licensors. You also agree not
								to: circumvent, remove, alter, deactivate,
								degrade or thwart any of the content protections
								in the Website; use any robot, spider, scraper
								or other automated means to access the Website;
								decompile, reverse engineer or disassemble any
								software or other products or processes
								accessible through the Website; insert any code
								or product or manipulate the content of the
								Website in any way; or, use any data mining,
								data gathering or extraction method. In
								addition, you agree not to upload, post, email
								or otherwise send or transmit any material
								designed to interrupt, destroy or limit the
								functionality of any computer software or
								hardware or telecommunications equipment
								associated with the Website, including any
								software viruses or any other computer code,
								files or programs.
							</span>
						</p>
						<p className="c19">
							<span className="c6">
								UNLESS YOU ARE BASED IN THE EEA
							</span>
							<span className="c16">,</span>
							<span className="c0">
								&nbsp;NEITHER NETFLIX NOR ITS AFFILIATED
								ENTITIES, NOR ANY OF ITS AGENCIES, NOR ANY OTHER
								PARTY INVOLVED IN CREATING, PRODUCING, OR
								DELIVERING THE EXPERIENCE, IS LIABLE FOR ANY
								DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT, OR
								PUNITIVE DAMAGES OR LOSSES ARISING OUT OF OR IN
								CONNECTION WITH YOUR ACCESS TO, OR USE OF, THE
								EXPERIENCE, EVEN IF ADVISED IN ADVANCE OF SUCH
								DAMAGES OR LOSSES, TO THE EXTENT PERMITTED BY
								APPLICABLE LAW. &nbsp;
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c0">
								Netflix may suspend or terminate your Netflix
								account or access to this Website if you fail to
								comply with these Terms.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c19">
							<span className="c6">
								The Website, including all content provided on
								the Website, is protected by copyright, trade
								secret or other intellectual property laws and
								treaties. Netflix is a registered trademark of
								Netflix, Inc. If you believe your work has been
								reproduced or distributed in a way that
								constitutes a copyright infringement or are
								aware of any infringing material available
								through the Website, please notify us by
								completing the Copyright Infringement Claims
								form (
							</span>
							<span className="c6 c34">
								<a
									className="c12"
									href="https://www.google.com/url?q=https://www.netflix.com/copyrights&amp;sa=D&amp;source=editors&amp;ust=1729804887620457&amp;usg=AOvVaw1K3fL6YMpeLTW2PAgSMArp"
								>
									www.netflix.com/copyrights
								</a>
							</span>
							<span className="c0">). </span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c38">
							<span className="c6 c9 c47">
								The Website may contain links to other websites
								owned and operated by third parties (&quot;Third
								Party Website(s)&quot;). These links are
								provided to you as a convenience only and
								visiting any Third Party Website is at your own
								risk. Netflix is not responsible for the content
								on such Third Party Websites and makes no
								representations or warranties with respect
								thereto. Your access and use of any such Third
								Party Websites is subject to their terms of use
								and privacy policies.{" "}
							</span>
						</p>
						<p className="c2">
							<span className="c8 c3"></span>
						</p>
						<p className="c40">
							<span className="c0">
								By using, visiting, or browsing, or uploading
								any UGC to the Website, you accept and agree to
								these Terms. If you do not accept these Terms
								and/or any updates to these Terms, please do not
								use this Website.
							</span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c14">
								Supplemental Privacy Disclosures for US
								Residents
							</span>
						</p>
						<p className="c2">
							<span className="c14 c25"></span>
						</p>
						<p className="c19 c9">
							<span className="c0">
								These disclosures apply to consumers who reside
								in states that have adopted consumer privacy
								laws that are in effect (collectively &ldquo;US
								State Privacy Laws&rdquo;) &nbsp;and supplements
								these Terms, to the extent applicable. Because
								of differences in certain state laws, this
								supplement is broken into two sections,
								California (CCPA) Specific Notices, applicable
								to California residents only, and a California
								(CCPA) and other State Specific Sections, that
								may apply to residents of one or more states
								with consumer privacy laws requiring such
								information.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c0"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c14">
								California Consumer Privacy Act (CCPA) Specific
								Privacy Notice
							</span>
						</p>
						<p className="c2">
							<span className="c25 c14"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c7">
								Notice at Collection
							</span>
						</p>
						<p className="c2 c9">
							<span className="c31 c14 c9"></span>
						</p>
						<p className="c19 c9">
							<span className="c6 c9">
								At or before the time of collection, the
								California Consumer Privacy Act
								(&ldquo;CCPA&rdquo;) requires that we provide a
								notice of our practices, including the
								categories of personal information collected and
								the purpose for which such information is
								collected or used (as stated in the{" "}
							</span>
							<span className="c6">
								Collection and Use of Information section)
							</span>
							<span className="c6 c9">
								, whether such information is &ldquo;sold or
								shared&rdquo; (in the{" "}
							</span>
							<span className="c6">
								CCPA Do Not Sell or Share My Personal
								Information section)
							</span>
							<span className="c6 c9">
								, and the criteria used to determine how long
								such information is retained (in the Data
								Retention section). We have provided these
								details throughout this section of these Terms.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c14">
								Personal Information We Collect
							</span>
						</p>
						<p className="c2 c9">
							<span className="c0"></span>
						</p>
						<p className="c19 c9">
							<span className="c6">
								We collect information that identifies, relates
								to, describes, is reasonably capable of being
								associated with, or could reasonably be linked,
								directly or indirectly, with a particular
								consumer or household (&ldquo;CCPA personal
								information&rdquo;). &nbsp;We have collected
								categories of CCPA personal information noted in
								the Collection and Use of Information section of
								these Terms within the last twelve (12) months.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c20 c9">
							<span className="c8 c16 c7">
								Use of CCPA personal information
							</span>
						</p>
						<p className="c20 c9">
							<span className="c5">
								We use categories of CCPA personal information
								listed above for the purposes noted in the
								Collection and Use of Information section of
								these Terms.
							</span>
						</p>
						<p className="c20 c9">
							<span className="c8 c16 c7">
								Categories of CCPA personal information
								disclosed for a business purpose
							</span>
						</p>
						<p className="c19 c9">
							<span className="c6 c7">
								We disclose the categories of CCPA personal
								information{" "}
							</span>
							<span className="c6">
								noted in the Collection and Use of Information
								section of these Terms{" "}
							</span>
							<span className="c6 c7">
								for business purposes. &nbsp;Specifically, we
								disclose these categories of information for
								business purposes to the following categories of
								third parties: Website Providers, the Netflix
								family of companies, an entity engaged in a
								business transfer, law enforcement, courts,
								governments and regulatory agencies.
							</span>
						</p>
						<p className="c2">
							<span className="c0"></span>
						</p>
						<p className="c9 c38">
							<span className="c8 c16 c7">
								Sources of CCPA personal information
							</span>
						</p>
						<p className="c23 c9">
							<span className="c8 c6 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c5">
								We explain our sources of information in the
								Collection of and Use Information section of our
								Terms.{" "}
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c7">
								Sensitive CCPA personal information{" "}
							</span>
						</p>
						<p className="c2 c9">
							<span className="c8 c6 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c5">
								We do not collect any information that may be
								considered sensitive personal information as
								defined by the CCPA.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c8 c6 c7"></span>
						</p>
						<p className="c38 c9">
							<span className="c8 c16 c7">
								CCPA Do Not Sell or Share My Personal
								Information
							</span>
						</p>
						<p className="c23 c9">
							<span className="c8 c16 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c6">
								We do not sell or share personal information as
								defined under CCPA.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c8 c6 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c7">
								Your California Privacy Rights
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c19 c9">
							<span className="c5">
								In addition to the rights stated under the Your
								Information and Rights section of these Terms,
								California residents have certain rights with
								respect to your personal information.{" "}
							</span>
						</p>
						<ul className="c1 lst-kix_list_2-0 start">
							<li className="c17 c36 li-bullet-0">
								<span className="c16 c7 c9">
									Right to Know/Access.{" "}
								</span>
								<span className="c6 c7">
									You have the right to request that we
									disclose: the categories and specific pieces
									of personal information we have collected
									about you; the categories of sources from
									which personal information are collected;
									our business or commercial purpose for
									collecting, using, disclosing, selling or
									sharing personal information; the categories
									of third parties to whom we disclose, sell,
									or share personal information; and the
									categories of personal information we have
									disclosed, sold, or shared about you for a
									business or commercial purpose. You also
									have a right to confirm whether we process
									your personal information, and to access and
									receive a copy of the specific personal
									information we have collected about you.
								</span>
							</li>
							<li className="c29 c17 c56 li-bullet-0">
								<span className="c16 c7">
									California Do Not Sell or Share My Personal
									Information.{" "}
								</span>
								<span className="c6 c7">
									We do not sell or share CCPA personal
									information as defined under CCPA.{" "}
								</span>
							</li>
							<li className="c29 c17 c58 li-bullet-0">
								<span className="c16 c7 c9">
									Right to Limit the Use of Sensitive Personal
									Information.
								</span>
								<span className="c6 c7 c9">
									&nbsp;As described above,{" "}
								</span>
								<span className="c5">
									we do not collect any information that may
									be considered Sensitive Personal Information
									as defined by the CCPA.
								</span>
							</li>
						</ul>
						<ul className="c1 lst-kix_list_3-0 start">
							<li className="c29 c17 li-bullet-0">
								<span className="c16 c7">
									Authorized Agents.{" "}
								</span>
								<span className="c6 c7">
									You have the right to exercise the rights
									described in this section using an
									authorized agent acting on your behalf. They
									can submit a request on your behalf to{" "}
								</span>
								<span className="c34 c6 c7">
									<a
										className="c12"
										href="mailto:privacy-site@netflix.com"
									>
										privacy-site@netflix.com
									</a>
								</span>
								<span className="c6 c7">
									&nbsp;along with a statement, certifying as
									may be required, that the agent is
									authorized to act on your behalf. In order
									to verify the request, we may ask you to
									verify your identity and your authorization
									of the agent to make requests on your
									behalf.
								</span>
							</li>
						</ul>
						<ul className="c1 lst-kix_list_5-0 start">
							<li className="c17 c42 li-bullet-0">
								<span className="c16 c7">
									Right to Non Discrimination.
								</span>
								<span className="c6 c7">
									&nbsp;You have a right not to be subject to
									&nbsp;discriminatory treatment for
									exercising any of your rights described
									under this section. We will not discriminate
									against you based on your exercise of any of
									your rights.
								</span>
							</li>
						</ul>
						<p className="c19">
							<span className="c6">
								For information on how to exercise your rights,
								please see the Your Information and Rights
								section of these Terms.
							</span>
						</p>
						<p className="c2">
							<span className="c33 c6 c55"></span>
						</p>
						<p className="c19 c9">
							<span className="c16 c7">
								Personal Information Rights under US State
								Privacy Laws
							</span>
						</p>
						<p className="c2 c9">
							<span className="c10 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c5">
								This section applies to residents of US States
								outside of California that have adopted US State
								Privacy Laws.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c19 c9">
							<span className="c16 c13 c7">
								Sale and Use of Personal Information{" "}
							</span>
						</p>
						<p className="c24">
							<span className="c6">
								We do not sell or share personal information as
								defined under US State Privacy Laws.
							</span>
						</p>
						<p className="c23">
							<span className="c14 c32"></span>
						</p>
						<p className="c38">
							<span className="c8 c16 c14">
								Use of De-Identified Information{" "}
							</span>
						</p>
						<p className="c23">
							<span className="c0"></span>
						</p>
						<p className="c38">
							<span className="c0">
								We may de-identify personal information in a
								secure manner when we determine it is
								appropriate as part of our business operations.
								We take reasonable measures to maintain and use
								the information in a de-identified manner. We do
								not make any attempts to re-identify such
								information, except as permitted under
								applicable law.
							</span>
						</p>
						<p className="c2 c9">
							<span className="c8 c6 c7"></span>
						</p>
						<p className="c19 c9">
							<span className="c8 c16 c7">
								Other Personal Information Rights under
								Applicable US State Privacy Laws
							</span>
						</p>
						<p className="c2 c9">
							<span className="c5"></span>
						</p>
						<p className="c19 c9">
							<span className="c5">
								In addition to the rights stated under the Your
								Information and Rights section of these Terms,
								and to the extent applicable under US State
								Privacy Laws, you may have the following rights
								in connection with your personal information:
							</span>
						</p>
						<p className="c2 c17">
							<span className="c5"></span>
						</p>
						<ul className="c1 lst-kix_list_4-0 start">
							<li className="c29 c17 li-bullet-0">
								<span className="c0">
									You have a right to opt-out of our
									&ldquo;sale&rdquo; or our use of personal
									information for &ldquo;targeted
									advertising&rdquo; purposes.{" "}
								</span>
							</li>
							<li className="c17 c18 li-bullet-0">
								<span className="c6 c7">
									If you are a consumer in a jurisdiction that
									recognizes the ability to use an authorized
									agent and wish to contact us through an
									authorized agent, the authorized agent can
									submit a request on your behalf at{" "}
								</span>
								<span className="c27 c6">
									<a
										className="c12"
										href="mailto:privacy-site@netflix.com"
									>
										privacy-site@netflix.com
									</a>
								</span>
								<span className="c6 c7">
									&nbsp;along with a statement signed by you
									certifying that the agent is authorized to
									act on your behalf. In order to verify the
									request and your identity, we may ask you to
									verify your identity.{" "}
								</span>
							</li>
							<li className="c17 c29 li-bullet-0">
								<span className="c6 c7">
									If you are in a jurisdiction that recognizes
									your ability to appeal a decision we have
									made in connection with your attempt to
									assert a right under applicable US State
									Privacy Laws, you may file an appeal of our
									decision refusing your request to exercise
									your rights under this Privacy Notice.
									Requests to change our policies or practices
									are not grounds for appeal. &nbsp;You may
									request an appeal of such a decision by
									contacting us at{" "}
								</span>
								<span className="c27 c6 c9">
									privacyappeals@netflix.com
								</span>
								<span className="c6 c7">
									, please provide the state that you are
									writing from, accompanied with documentation
									you may have regarding the matter you are
									appealing. If your jurisdiction allows you
									to file a complaint with the state&rsquo;s
									Attorney General&rsquo;s Office regarding
									any concerns with the result of your appeal
									request, you may do so by using the
									following links as may be applicable to you.
									You may submit a complaint to the Attorney
									General&rsquo;s Office by selecting the
									appropriate link: Virginia (
								</span>
								<span className="c27 c6">
									<a
										className="c12"
										href="https://www.google.com/url?q=http://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint&amp;sa=D&amp;source=editors&amp;ust=1729804887626953&amp;usg=AOvVaw26D5T6QoJRwFTR7BS5IVHT"
									>
										www.oag.state.va.us/consumer-protection/index.php/file-a-complaint
									</a>
								</span>
								<span className="c6 c7">), Colorado (</span>
								<span className="c27 c6">
									<a
										className="c12"
										href="https://www.google.com/url?q=https://coag.gov/file-complaint/&amp;sa=D&amp;source=editors&amp;ust=1729804887627194&amp;usg=AOvVaw1TDVKK6ox7cyNT_k7l3_eH"
									>
										coag.gov/file-complaint
									</a>
								</span>
								<span className="c6 c7">
									), and Connecticut (
								</span>
								<span className="c27 c6">
									<a
										className="c12"
										href="https://www.google.com/url?q=https://portal.ct.gov/AG/Common/Complaint-Form-Landing-page&amp;sa=D&amp;source=editors&amp;ust=1729804887627409&amp;usg=AOvVaw3NZk1uPH75eYzQi0J3ykpC"
									>
										portal.ct.gov/AG/Common/Complaint-Form-Landing-page
									</a>
								</span>
								<span className="c6 c7">). </span>
							</li>
						</ul>
						<p className="c2">
							<span className="c10 c14"></span>
						</p>
						<p className="c19">
							<span className="c6">
								For information on how to exercise your rights,
								please see the Your Information and Rights
								section of these Terms.
							</span>
						</p>
						<p className="c23">
							<span className="c10 c14 c39"></span>
						</p>
						<h3 className="c19 c9" id="h.2et92p0">
							<span className="c16 c13 c7 c45">
								Supplemental Privacy Disclosures for EEA/UK
								Residents
							</span>
						</h3>
						<p className="c2">
							<span className="c25 c14"></span>
						</p>
						<p className="c38">
							<span className="c8 c16 c14">Legal bases</span>
						</p>
						<p className="c23">
							<span className="c8 c16 c14"></span>
						</p>
						<p className="c46">
							<span className="c6">
								Our legal basis for collecting and using the
								personal information described in these Terms
								will depend on the personal information
								concerned and the specific context in which we
								collect and use it. We will normally collect
								personal information from you where we need the
								personal information to perform a contract with
								you (for example, in order to provide our
								service), where the processing is in our
								legitimate interests and not overridden by your
								data protection interests or fundamental rights
								and freedoms (for example, to support internal
								operations, such as to conduct research and
								analysis to address the performance of our
								Website, to generate aggregated or de-identified
								reporting for our use, and to support the
								performance of our site and to remember choices
								you have made, or for safety, security and fraud
								prevention), or where we have your consent to do
								so (for example, collecting your consent to send
								marketing messages). In some cases, we may also
								have a legal obligation to collect personal
								information from you or may otherwise need the
								personal information to protect your vital
								interests or those of another person (for
								example, to confirm your identity), or to comply
								with law and enforce these Terms. For questions
								about our legal bases, please contact our Data
								Protection Officer/Privacy Office by email
								at{" "}
							</span>
							<span className="c6 c27">
								<a
									className="c12"
									href="mailto:privacy-site@netflix.com"
								>
									privacy-site@netflix.com
								</a>
							</span>
							<span className="c10 c14">.</span>
						</p>
						<p className="c46 c30">
							<span className="c10 c14"></span>
						</p>
						<p className="c19">
							<span className="c0">&nbsp;</span>
						</p>
						<p className="c19">
							<span className="c0">
								Last updated October 2024
							</span>
						</p>
						<div>
							<p className="c4 c51 c30">
								<span className="c25 c14"></span>
							</p>
						</div>
					</div>
				</div>

				<div className="main__background page__background"></div>
				<Footer />
			</>
		);
	} else if (thisPageName === "faq") {
		return (
			<>
				<div className="page__container row no-gutters width_ctrl my-5 pt-5">
					<div className="page__content">
						<h3>Need website RSVP help?</h3>

						<p className="text-center">
							<span>Please contact us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span> with “RSVP HELP” in the subject line.</span>
						</p>

						<h3>Have feedback regarding an event?</h3>

						<p className="text-center">
							<span>Please contact us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>
								{" "}
								with “EVENT FEEDBACK” in the subject line.
							</span>
						</p>

						<h3>
							Need to update your email or mailing address for
							invites and screeners?{" "}
						</h3>

						<p className="text-center">
							<span>Email us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>
								{" "}
								with “SCREENER HELP” and your guild affiliation
								in the subject line.
							</span>
						</p>

						<h3>Looking for our screener site?</h3>

						<p className="text-center">
							<strong>
								Go to{" "}
								<a
									href="https://fyc.netflix.com/"
									target="_blank"
									rel="noopener noreferrer"
								>
									fyc.netflix.com
								</a>
							</strong>
						</p>

						<h3>Need a viewing code?</h3>

						<p className="text-center">
							<span>Email us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>
								{" "}
								with “SCREENER HELP” and your guild affiliation
								in the subject line.
							</span>
						</p>

						<h3>Missing screeners?</h3>

						<p className="text-center">
							<span>Email us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>
								{" "}
								with “SCREENER HELP” and your guild affiliation
								in the subject line.
							</span>
						</p>

						<h3>I have additional questions…</h3>

						<p className="text-center">
							<span>Please contact us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>.</span>
						</p>

						<h3>Need ADA assistance onsite?</h3>

						<p className="text-center">
							<span>Please contact us at </span>
							<a href="mailto:awards@netflix.com">
								<span>awards@netflix.com</span>
							</a>
							<span>
								{" "}
								with “ADA ACCOMMODATIONS REQUEST” in the subject
								line.
							</span>
						</p>
						<h3>Some additional key information:</h3>

						<p className="text-center">
							<span>
								Only Television Academy Members with valid
								membership cards and their guests will be
								admitted to the events noted as “ATAS Official
								Event”.{" "}
							</span>
						</p>
						<p className="text-center">
							<span>
								All events are first come, first served. There
								will be a wait in line upon entrance.{" "}
							</span>
						</p>
						<p className="text-center">
							<span>
								Invites are non-transferable and all
								cancelations are final.
							</span>
						</p>
						<p className="text-center">
							<span>
								Parking for each event hosted at FYSEE is
								complimentary.
							</span>
						</p>
					</div>
				</div>
				<div className="main__background page__background"></div>
				<Footer />
			</>
		);
	} else if (thisPageName === "maintenance") {
		return (
			<>
				<div className="page__container row no-gutters  width_ctrl my-5 pt-5">
					<div className="page__content mt-5">
						<h3>Temporarily Down for Maintenance</h3>
					</div>
				</div>
			</>
		);
	} else {
		return "";
	}
};

export default ContentPage;
