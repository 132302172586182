import React, { useState, useLayoutEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Moment from "react-moment";
import { GuestForm } from "./GuestForm";
import { fetchGuilds } from "../../utils/api/helpers/guild";
import { createRsvp } from "../../utils/api/helpers/rsvp";

import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha,
} from "react-simple-captcha";

const EventRSVPForm = ({
	event_slug,
	allowGuests,
	title,
	start_date,
	start_time,
	guestThreshold,
}) => {
	const [guild_list, setGuildList] = useState([]),
		[formIsValid, setFormIsValid] = useState(false),
		[captchaTried, setCaptchaTried] = useState(false),
		[captchaValid, setCaptchaValid] = useState(false),
		[formSubmitClicked, setFormSubmitClicked] = useState(false),
		[formSubmitted, setFormSubmitted] = useState(false);

	const [name, setName] = useState(""),
		[email, setEmail] = useState(""),
		[guild_affiliation, setGuildAffiliation] = useState(null),
		[venue_id, setVenueId] = useState(null),
		[guestList, setGuestList] = useState([]),
		[rsvp_status, setRsvpStatus] = useState(null);

	const captchaRef = useRef();

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed && event_slug) {
			setFormIsValid(false);
			setFormSubmitClicked(false);
			setFormSubmitted(false);
			setName("");
			setEmail("");
			setGuildAffiliation("");
			setVenueId("");
			setGuestList([]);
			setRsvpStatus(null);
			setCaptchaTried(false);
			setCaptchaValid(false);

			loadCaptchaEnginge(6);
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [event_slug]);

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed)
			fetchGuilds().then((result) => {
				console.log(result);
				if (!result.error) setGuildList(result.data);
			});
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateGuestList = (idx, guest, remove) => {
		let l = guestList.slice();
		if (remove) {
			l = l.filter((el) => {
				return el.name !== guest.name;
			});
			setGuestList(l);
		} else {
			if (idx < 0) {
				l.push(guest);
			} else {
				l[idx] = guest;
			}
			setGuestList(l);
		}
	};

	const person = () => {
		return {
			name: name,
			email: email,
			guild_id: guild_affiliation,
			venue_id: venue_id,
			guests: guestList,
		};
	};

	const reset = () => {
		setName("");
		setEmail("");
		setGuildAffiliation(null);
		setGuestList([]);
	};

	const submitRsvp = () => {
		createRsvp(event_slug, person()).then((result) => {
			setRsvpStatus(result);
			setFormSubmitted(true);
		});
	};

	return (
		<div className="rsvpform__container w-100 p-3 d-flex flex-column justify-content-start">
			{!formSubmitClicked ? (
				<Form>
					<Row className="mb-4">
						<Col lg={12}>
							<div className="d-none rsvpform__event--time mt-2">
								<span className="font-bold">
									<Moment format="dddd, MMMM Do YYYY, h:mm a">
										{start_date}
									</Moment>
								</span>{" "}
								- <span>Venue</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={12}>
							<Form.Group controlId="signup_name">
								<Form.Control
									value={name}
									required
									style={{
										opacity: 1,
										borderBottom: "1px solid #666",
									}}
									onChange={(e) => setName(e.target.value)}
									placeholder="Name"
								/>
							</Form.Group>
							{!formIsValid && name === "" ? (
								<div className="validation__message">
									Please enter a Name
								</div>
							) : (
								""
							)}
						</Col>
					</Row>
					<Row>
						<Col lg={6}>
							<Form.Group controlId="signup_email">
								<Form.Control
									type="email"
									required
									style={{
										opacity: 1,
										borderBottom: "1px solid #666",
									}}
									value={email}
									placeholder="Email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</Form.Group>
							{!formIsValid &&
							(email === "" || email.indexOf("@") < 0) ? (
								<div className="validation__message">
									Please enter a valid email address
								</div>
							) : (
								""
							)}
						</Col>
						<Col lg={6}>
							<Form.Group
								controlId="signup_event"
								style={{ paddingRight: "10px" }}
							>
								<Form.Control
									as="select"
									required
									style={{
										color: "#fff",
										background: "transparent",
										border: "none",
										borderBottom: "1px solid",
									}}
									value={guild_affiliation || ""}
									onChange={(e) =>
										setGuildAffiliation(e.target.value)
									}
								>
									<option value={null}>
										Select Guild Affiliation
									</option>

									{guild_list.map((ga, i) => {
										return (
											<option key={i} value={ga.guild_id}>
												{ga.name}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
							{!formIsValid &&
							(guild_affiliation <= 0 ||
								guild_affiliation ===
									"Select Guild Affiliation") ? (
								<div className="validation__message">
									Please select a Guild Affiliation
								</div>
							) : (
								""
							)}
						</Col>

						{allowGuests && guestThreshold > 0 ? (
							<div className="p-3 w-100">
								<div className="row">
									<div className="col-12 d-flex mb-3 align-items-center">
										<div
											className="events__rsvp--header mb-1"
											style={{ fontSize: "1rem" }}
										>
											GUEST
										</div>

										{guestList.length < guestThreshold ? (
											<Button
												className="ms-auto button__outline button__box"
												onClick={(e) =>
													updateGuestList(-1, {
														name: "",
													})
												}
											>
												<span className="label-small">
													+ Add Guest
												</span>
											</Button>
										) : (
											<span className="ms-auto button__outline button__box">
												{guestThreshold} guest
												{guestThreshold > 1
													? "s"
													: ""}{" "}
												{guestThreshold > 1
													? "are"
													: "is"}{" "}
												allowed for this event.
											</span>
										)}
									</div>
								</div>
								{guestList
									? guestList.map((gl, i) => (
											<GuestForm
												key={i}
												idx={i}
												updateGuest={updateGuestList}
											/>
										))
									: ""}
							</div>
						) : (
							""
						)}

						<Col
							lg={12}
							className="my-4 d-flex flex-column flex-md-row  justify-content-center"
						>
							<div className="d-flex flex-column flex-md-row align-items-center">
								<LoadCanvasTemplateNoReload />
								<Form.Control
									ref={captchaRef}
									className="mx-3"
									style={{
										opacity: 1,
										borderBottom: "1px solid #666",
									}}
									placeholder="Enter Captcha Code"
								/>
							</div>

							<div className="mt-3 mt-md-0 d-flex align-items-center justify-content-center">
								<Button
									className="button__box"
									onClick={(e) => {
										if (
											person().name !== "" &&
											person().email !== "" &&
											person().email.indexOf("@") > 0
										) {
											if (person().guests.length > 0) {
												const hasBlanks =
													person().guests.filter(
														(o) => {
															return (
																o.name === ""
															);
														},
													);

												if (hasBlanks.length > 0) {
													setFormIsValid(false);
												} else {
													if (
														validateCaptcha(
															captchaRef.current
																.value,
														)
													) {
														setCaptchaValid(true);
														setFormIsValid(true);
														setFormSubmitClicked(
															true,
														);
														submitRsvp();
														setFormSubmitted(true);
													} else {
														setCaptchaTried(true);
														setCaptchaValid(false);
													}
												}
											} else {
												if (
													validateCaptcha(
														captchaRef.current
															.value,
													)
												) {
													setCaptchaValid(true);
													setFormIsValid(true);
													setFormSubmitClicked(true);
													submitRsvp();
													setFormSubmitted(true);
												} else {
													setCaptchaTried(true);
													setCaptchaValid(false);
												}
											}
										} else {
											setFormIsValid(false);
										}
									}}
								>
									{"Submit RSVP"}
								</Button>
								<Button
									type="reset"
									className="ms-2 button__box button__grey"
									onClick={() => reset()}
								>
									Clear Form
								</Button>
							</div>
						</Col>
					</Row>
					{captchaTried && !captchaValid ? (
						<Row className="justify-content-center font-weight-bold text-red">
							Code does not match, please try again
						</Row>
					) : null}
				</Form>
			) : (
				<>
					{!formSubmitted ? (
						<div className="text-center my-5">Sending RSVP...</div>
					) : (
						<>
							{(() => {
								switch (rsvp_status) {
									case "DUPE_EMAIL":
										return (
											<div className="dupeuser text-center my-5">
												This name has already been
												submitted for this event.
											</div>
										);
									case "CONFIRM":
										return (
											<div className="confirm text-center my-5">
												Thank you for submitting your
												RSVP to{" "}
												<span className="font-weight-bold">
													{title}
												</span>
												!<br />
												<br />
												You will receive a reply via
												email shortly.
											</div>
										);
									case "DENY":
										return (
											<div className="deny text-center my-5">
												Capacity for{" "}
												<span className="font-weight-bold">
													{title}
												</span>{" "}
												has been reached.
											</div>
										);
									case "GUEST_EXCEEDED":
										return (
											<div className="guestexceed text-center my-5">
												Your guests exceed the number of
												allowed guests for{" "}
												<span className="font-weight-bold">
													{title}
												</span>
												. <br />
												<br />
												Please refresh this page and
												re-submit with fewer guests.
											</div>
										);
									case "REQUEST":
										return (
											<div className="request text-center my-5">
												Thank you for submitting your
												RSVP to{" "}
												<span className="font-weight-bold">
													{title}
												</span>
												! <br />
												<br />
												You will receive a reply via
												email shortly.
											</div>
										);
									default:
										return (
											<div className="default text-center my-5">
												Thank you for submitting your
												RSVP to{" "}
												<span className="font-weight-bold">
													{title}
												</span>
												!<br />
												<br />
												You will receive a reply via
												email shortly.
											</div>
										);
								}
							})()}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default EventRSVPForm;
