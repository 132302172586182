import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import CompetitionsAwards from "../CompetitionsAwards/CompetitionsAwards";
import SidebarMeta from "./SidebarMeta";
import SagIneligible from "./SagIneligible";

const Sidebar = ({
	award_count,
	titleName,
	titleId,
	titleSlug,
	socialFB,
	socialTW,
	socialIG,
	sagIneligible,
	titleTTImage,
	reviewQuote,
	reviewCredit,
	viewingLink,
	availableOn,
	hasAwards,
	competitions,
	indeeProjectKey,
	activeProjectKey,
	setActiveProjectKey,
	isLoggedIn,
	displayPinModal,
	setDisplayPinModal,
	setDisplayScreenerProjectList,
	setActiveTitleName,
	hasContent,
	isMobile,
	bannerHeight,
	indeeApiChecked,
}) => {
	const match = useParams();

	const selectedType = window.location.pathname.includes("pga")
		? "pga"
		: window.location.pathname.includes("ves")
			? "ves"
			: "";

	useEffect(() => {
		let subscribed = true;
		if (subscribed) {
			Moment.globalLocale = "en-us";
		}
		return () => {
			subscribed = false;
		};
	}, []);

	return (
		<div
			className="d-none d-md-block col-12 col-md-4 sidebar__container--titlepage px-4 px-md-0"
			style={{
				position: isMobile ? "sticky" : "relative",
				top: isMobile ? bannerHeight : "auto",
				zIndex: 4,
			}}
		>
			<div
				className={"sidebar__container--titlepage-sticky"}
				style={{
					// height:hasContent?'100vh':'60vh'
					height: isMobile ? "auto" : "100vh",
					// top:hasContent?0:400
				}}
			>
				<div className="sidebar__container--inner d-flex flex-column justify-content-start">
					{/*<div className={"titlepage__logo--container px-0 px-md-3 d-flex flex-column " + ((award_count) ? '' : 'flex-fill justify-content-center align-items-center')}>*/}
					<div
						className={
							"titlepage__logo--container px-0 px-md-3 d-flex flex-column "
						}
						style={{
							position:
								!isMobile && (!hasContent || !hasAwards)
									? "sticky"
									: "relative",
							top:
								!isMobile && (!hasContent || !hasAwards)
									? "33vh"
									: "auto",
						}}
					>
						{titleTTImage !== "" ? (
							<a
								href={
									"/" +
									match.categorySlug +
									"/" +
									match.titleSlug
								}
								className="titletreatment-container d-flex justify-content-center align-items-center mb-0"
							>
								<img
									src={titleTTImage}
									style={{ maxHeight: "250px" }}
									className="titletreatment my-3"
									alt={titleName}
								/>
							</a>
						) : (
							""
						)}

						{!isMobile ? (
							<SidebarMeta
								titleName={titleName}
								titleSlug={titleSlug}
								socialFB={socialFB}
								socialTW={socialTW}
								socialIG={socialIG}
								reviewQuote={reviewQuote}
								reviewCredit={reviewCredit}
								indeeProjectKey={indeeProjectKey}
								setActiveProjectKey={setActiveProjectKey}
								isLoggedIn={isLoggedIn}
								setDisplayPinModal={setDisplayPinModal}
								setDisplayScreenerProjectList={
									setDisplayScreenerProjectList
								}
								setActiveTitleName={setActiveTitleName}
								hasAwards={hasAwards}
								sagIneligible={sagIneligible}
								availableOn={availableOn}
								indeeApiChecked={indeeApiChecked}
							/>
						) : null}
					</div>

					<div
						className={
							" sidebar__scrollcontainer--outer flex-fill " +
							(hasAwards ? "d-block" : "d-none")
						}
					>
						<div className="scroll-container sidebar__scrollcontainer--inner">
							<div className="sidebar__scrollcontainer--content">
								{hasAwards && competitions ? (
									<div className="d-none d-md-flex flex-md-fill flex-column">
										<CompetitionsAwards
											show_id={titleId}
											slug={titleSlug}
											titleName={titleName}
											award_count={award_count}
											competitions={competitions}
										/>
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>

					{hasAwards && award_count > 1 ? (
						<div
							className="text-center text-small text-uppercase my-3 d-none d-md-flex justify-content-center"
							style={{
								fontSize: ".6rem",
								letterSpacing: ".15rem",
								color: "#999",
							}}
						>
							<button
								className="button__outline px-3"
								style={{
									fontSize: ".6rem",
									letterSpacing: ".3em",
								}}
							>
								<a
									href={`/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/awards`}
									style={{ letterSpacing: ".05rem" }}
									className=""
								>
									View{" "}
									{award_count > 1
										? "All Nominations"
										: "The Nomination"}
								</a>
							</button>
						</div>
					) : (
						""
					)}

					<SagIneligible show={hasAwards && sagIneligible} />
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
