import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { unsubscribe } from "../../utils/api/helpers/subscription";

const Unsubscribe = () => {
	const { hash } = useParams();
	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleSubmit = () => {
		unsubscribe({ refHash: hash }).then(() => {
			setFormSubmitted(true);
		});
	};

	useEffect(() => {
		let subscribed = true;
		if (subscribed) {
			window.scrollTo({ left: 0, top: 0, behavior: "auto" });
			setFormSubmitted(false);
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hash]);

	return (
		<>
			<div className="page__container mt-5 pt-5 mb-5">
				<div className="width_ctrl">
					<div
						className="my-5 font-weight-bold  text-center"
						style={{ fontSize: "3rem" }}
					>
						Unsubscribe
					</div>

					<div
						className="text-center my-5 w-75 mx-auto"
						style={{ lineHeight: "1.2" }}
					>
						If you wish to unsubscribe from receiving any emails,
						click below.
					</div>

					{!formSubmitted ? (
						<Form>
							<Row>
								<Col
									lg={12}
									className="my-2 d-flex justify-content-center"
								>
									<Button
										className="button__box px-4 cursor-pointer"
										onClick={handleSubmit}
									>
										Unsubscribe
									</Button>
								</Col>
							</Row>
						</Form>
					) : (
						<>
							<div className="text-center my-5 font-weight-bold">
								Thank you for confirming your unsubscription.
							</div>
						</>
					)}
				</div>
			</div>

			<div className="main__background page__background" />
		</>
	);
};

export default Unsubscribe;
