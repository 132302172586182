import React, { useState, useEffect } from "react";
import { Event, TrackingEvents } from "../../utils/Tracking";
import { useParams, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { listGenres } from "../../utils/api/helpers/genre";

const Subnav = () => {
	const match = useParams();

	const [subNavDropDownActive, setSubNavDropDownActive] = useState(false),
		[selectedCategory, setSelectedCategory] = useState(""),
		[categories, setCategories] = useState(null);

	const subNavToggle = () => {
		setSubNavDropDownActive(!subNavDropDownActive);
	};
	const subNavBlur = () => {
		setSubNavDropDownActive(false);
	};
	const subNavClickLink = (genreName) => {
		setSelectedCategory(genreName);
		subNavBlur();
		Event(
			TrackingEvents.categories.INTERNAL_NAVIGATION,
			TrackingEvents.actions.NAV_CATEGORY_SUBNAV,
			TrackingEvents.labels.NAV_CATEGORY_SUBNAV,
		);
	};

	const location = useLocation();

	const selectedType = location.pathname.includes("/pga")
		? "pga"
		: location.pathname.includes("/ves")
			? "ves"
			: "";

	useEffect(() => {
		// console.log('subnav')
		listGenres(selectedType).then((result) => {
			// console.log("Genres", result, match)
			if (result.data && result.status === 200) {
				setCategories(result.data);
				result.data.map((g, i) => {
					if (g.slug === match.categorySlug) {
						return setSelectedCategory(g.name);
					} else {
						return false;
					}
				});
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType]);

	return (
		<div className="subnav__container d-flex">
			<div className="d-flex align-self-stretch align-items-stretch justify-content-center w-100">
				<OutsideClickHandler
					onOutsideClick={() => {
						subNavBlur();
					}}
				>
					<div
						className={
							"dropdown__container h-100 d-flex align-items-center position-relative " +
							(subNavDropDownActive ? "active" : "")
						}
					>
						<button
							onClick={() => {
								subNavToggle();
							}}
							className="subnav__center  d-flex align-self-stretch align-items-center justify-content-center"
						>
							<div className="dropdown__select d-flex align-self-stretch align-items-center">
								<h1 className="dropdown__label">
									{selectedCategory}
								</h1>
								<span
									className="dropdown__arrow icon ps-1 ps-md-3"
									style={{
										transform: subNavDropDownActive
											? "rotate(180deg)"
											: "none",
									}}
								>
									<svg
										viewBox="0 0 24 24"
										width="18"
										height="18"
										stroke="currentColor"
										strokeWidth="2"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
										className=""
									>
										<polyline points="6 9 12 15 18 9"></polyline>
									</svg>
								</span>
							</div>
						</button>

						<div className={"dropdown__content "}>
							<ul>
								{categories
									? categories.map((cat, i) => {
											return (
												<li key={i}>
													<a
														onClick={() => {
															subNavClickLink(
																cat.name,
															);
														}}
														className={
															"dropdown__item " +
															(cat.slug ===
															match.categorySlug
																? "dropdown__item--selected"
																: "")
														}
														href={`/${selectedType ? selectedType + "/" : ""}${cat.slug}`}
													>
														{cat.name}
													</a>
												</li>
											);
										})
									: ""}
								{/* categories  ?
                              categories.map( (cat, i) => {
                                  return (
                                      <li key={ i }>
                                          <a onClick={() => {subNavClickLink(cat.name)}} className={ "dropdown__item " + (cat.slug === match.categorySlug ? 'dropdown__item--selected' : "") } href={ "/" + cat.slug }>{ cat.name }</a>
                                      </li>
                                  )
                              })
                              :""
                          */}
							</ul>
						</div>
					</div>
				</OutsideClickHandler>
			</div>
		</div>
	);
};

export default Subnav;
