import React from "react";

const SagIneligible = ({ show }) => {
	if (show) {
		return (
			<div className="sag-ineligible py-2 justify-content-center align-items-center text-center text-small">
				<div className="">
					This content is not eligible for SAG Awards® consideration.
				</div>
			</div>
		);
	} else {
		return null;
	}
};

export default SagIneligible;
