import { Accordion, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { fetchIndeeProjects } from "../../utils/api/helpers/indee";
import ScreenerGrid from "./ScreenerGrid";
import netflixLogo from "../../Common/_assets/img/logo/netflix-logo-ribbon.png";
import ReactGA from "react-ga4";

const ScreenerProjectList = ({
	activeTitleName,
	displayScreenerProjectList,
	setDisplayScreenerProjectList,
	activeProjectKey,
	setActiveProjectKey,
	setUnauth,
	doLogout,
	loggingOut,
}) => {
	const [loaded, setLoaded] = useState(false),
		[projectLoaded, setProjectLoaded] = useState(false),
		[projectCount, setProjectCount] = useState(0),
		[projectList, setProjectList] = useState([]),
		[activeAccordion, setActiveAccordion] = useState([]),
		[projectKeyInList, setProjectKeyInList] = useState(true);

	useEffect(() => {
		if (!loaded && displayScreenerProjectList) {
			fetchIndeeProjects().then((result) => {
				// console.log('project list',result);
				setLoaded(true);
				if (result.data.error && result.data.message.unauth) {
					setDisplayScreenerProjectList(false);
					setUnauth();
				} else {
					setProjectCount(result.data.count);
					setProjectList(result.data.results);
					// console.log('project list',result.data, activeProjectKey, activeTitleName)
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaded, displayScreenerProjectList, activeProjectKey, activeTitleName]);

	useEffect(() => {
		if (activeAccordion && !projectLoaded) {
			setProjectLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAccordion, projectLoaded]);

	useEffect(() => {
		if (activeProjectKey) {
			// console.log(projectList)
			if (projectList.some((e) => e.key === activeProjectKey)) {
				setProjectKeyInList(true);
				// console.log('project exists')
				const elmnt = document.getElementById(activeProjectKey);
				elmnt.scrollIntoView();
			} else {
				setProjectKeyInList(false);
				// console.log('project does NOT exist')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeProjectKey, projectList]);

	return (
		<>
			<Modal
				centered
				size={"xl"}
				show={displayScreenerProjectList}
				onHide={() => setDisplayScreenerProjectList(false)}
			>
				<Modal.Header>
					<Modal.Title>
						<div
							className={
								"d-flex align-items-center justify-content-center flex-fill"
							}
						>
							<div
								className={
									"d-flex align-items-center justify-content-center pe-3"
								}
							>
								<img
									src={netflixLogo}
									className="header__logo"
									alt="Netflix"
									style={{ maxWidth: "15px" }}
								/>
							</div>
							<div
								className={
									"d-flex align-items-center justify-content-center"
								}
							>
								<span
									className={""}
									style={{
										fontSize: "14px",
										fontWeight: "300",
										color: "#aaa",
									}}
								>
									FYC | Screeners
								</span>
							</div>
							<div
								className={
									"d-flex align-items-center justify-content-center ms-auto"
								}
							>
								<span
									onClick={() => {
										doLogout();
									}}
									className={""}
									style={{
										fontSize: "10px",
										textTransform: "uppercase",
										fontWeight: "300",
										color: "#e50914",
										cursor: "pointer",
									}}
								>
									{loggingOut ? "Logging out..." : "Logout"}
								</span>

								<span
									onClick={() => {
										setDisplayScreenerProjectList(false);
									}}
									className={"mx-4"}
									style={{
										fontSize: "10px",
										textTransform: "uppercase",
										fontWeight: "300",
										color: "#e50914",
										cursor: "pointer",
									}}
								>
									Exit Screeners
								</span>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{loaded ? (
						<>
							{!projectKeyInList ? (
								<div
									className={"my-4"}
									style={{
										color: "#ccc",
										backgroundColor:
											"rgba(255,255,255,.05)",
										borderRadius: 7,
										padding: "1.5rem",
									}}
								>
									Your code does not have access to screeners
									for <strong>{activeTitleName}</strong>.
									Please see below for your list of available
									screeners.
								</div>
							) : null}
							<Accordion
								defaultActiveKey={
									activeProjectKey ? activeProjectKey : null
								}
								onSelect={(e) => {
									setActiveAccordion(e);
									setProjectLoaded(false);
								}}
							>
								<>
									{projectCount > 0 ? (
										<>
											{projectList.map((p, i) => {
												return (
													<Accordion.Item
														eventKey={p.key}
														key={i}
														id={p.key}
														onClick={() => {
															setActiveProjectKey(
																p.key,
															);
															ReactGA.event({
																category:
																	"Screener Nav",
																action: "View Screener List",
																label: p.name,
															});
														}}
													>
														<Accordion.Header>
															{p.name}
														</Accordion.Header>
														{p.key ===
														activeProjectKey ? (
															<Accordion.Body>
																{/*{*/}
																{/*    !projectLoaded ? <p>Loading...</p>*/}
																{/*        : (*/}
																{/*            <ScreenerGrid projectKey={p.key}*/}
																{/*                          activeKey={ activeAccordion }*/}
																{/*                          projectName={p.name}/>*/}
																{/*        )*/}
																{/*}*/}
																<ScreenerGrid
																	projectKey={
																		p.key
																	}
																	activeKey={
																		activeProjectKey
																	}
																	projectName={
																		p.name
																	}
																/>
															</Accordion.Body>
														) : null}
													</Accordion.Item>
												);
											})}
										</>
									) : (
										<div
											className={"my-4"}
											style={{ color: "#ccc" }}
										>
											Your code does not have access to
											screeners at this time.
										</div>
									)}
								</>
							</Accordion>
						</>
					) : (
						<div className={"my-4"} style={{ color: "#ccc" }}>
							Loading available Screeners...
						</div>
					)}
				</Modal.Body>
				<Modal.Footer />
			</Modal>
		</>
	);
};

export default ScreenerProjectList;
