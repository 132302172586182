import React from "react";
import Competition from "./Competition";
import sidebarShadow from "../../../Common/_assets/img/placeholder/sidebar-shadow.png";

const CompetitionsAwardsColumns = ({
	competitions,
	titleName,
	show_id,
	slug,
	award_count,
}) => {
	return (
		<>
			<div className="d-flex mb-5 pb-5 flex-md-fill flex-column align-items-center">
				<div className="sidebar__container--nomineelist-wrapper nomination__columns flex-md-fill row col-12 col-md-8">
					<div
						className="d-none font-weight-light text-uppercase mt-2 mb-4 text-center "
						style={{ fontSize: ".7rem", letterSpacing: ".3rem" }}
					>
						Nominations
					</div>

					{competitions.map((competition, i) => {
						return (
							<div
								key={i}
								className="col-12 mt-3 d-flex justify-content-center"
							>
								<div
									className=""
									style={{
										display: "flex",
										flexDirection: " column",
										justifyContent: "center",
									}}
								>
									<Competition
										competition={competition}
										slug={slug}
										award_count={award_count}
									/>
								</div>
							</div>
						);
					})}

					<div className="sidebar__container--shadow d-none">
						<img src={sidebarShadow} className="" alt="Netflix" />
					</div>
				</div>
			</div>
		</>
	);
};

export default CompetitionsAwardsColumns;
