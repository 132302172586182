import React from "react";
import Competition from "./Competition";
import sidebarShadow from "../../../Common/_assets/img/placeholder/sidebar-shadow.png";

const CompetitionsAwards = ({
	competitions,
	titleName,
	show_id,
	slug,
	award_count,
}) => {
	return (
		<>
			<div
				className="font-weight-light text-uppercase text-center "
				style={{
					opacity: 0.5,
					fontSize: ".7rem",
					letterSpacing: ".3rem",
					marginTop: "2rem",
					marginBottom: "2rem",
				}}
			>
				Nominations
			</div>

			{competitions
				? competitions.map((competition, i) => {
						return (
							<React.Fragment key={i}>
								<div className="sidebar__container--nomineelist-wrapper nomination__sidebar flex-md-fill d-flex flex-column">
									<Competition
										competition={competition}
										slug={slug}
										award_count={award_count}
									/>
								</div>
							</React.Fragment>
						);
					})
				: null}

			<div className="sidebar__container--shadow d-none">
				<img src={sidebarShadow} className="" alt="Netflix" />
			</div>
		</>
	);
};

export default CompetitionsAwards;
