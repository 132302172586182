import React, { useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import EventExcerptTitlePage from "../../Event/EventExcerptTitlePage";
import YouTube from "react-youtube";
import CompetitionsAwards from "../CompetitionsAwards/CompetitionsAwards";
import MediaGrid from "../TitleMedia/MediaGrid";
import SidebarMeta from "../Sidebar/SidebarMeta";
import SagIneligible from "../Sidebar/SagIneligible";
import TitleMediaMP4 from "../TitleMedia/Video/TitleMediaMP4";

const TitleDetailContent = ({
	s3Url,
	titleId,
	trailerId,
	titleSlug,
	categorySlug,
	titleName,
	award_count,
	competitions,
	scripts,
	hasMediaRead,
	hasVideos,
	hasEvents,
	hasPress,
	hasAwards,
	hasContent,
	fyseeVideoList,
	taglist_videos,
	taglist_press,
	taglist_press_videos,
	eventList,
	indeeProjectKey,
	indeeProjectLoaded,
	scrolled,
	setScrolled,
	isLoggedIn,
	activeSection,
	setActiveSection,
	isMobile,
	socialFB,
	socialTW,
	socialIG,
	reviewQuote,
	reviewCredit,
	setActiveProjectKey,
	setDisplayPinModal,
	setDisplayScreenerProjectList,
	setActiveTitleName,
	sagIneligible,
	availableOn,
	hasPresentations,
	taglist_presentations,
	indeeApiChecked,
}) => {
	const match = useParams();

	const videoFullRef = useRef();

	const [trailerLoaded, setTrailerLoaded] = useState(false),
		[sectionLoaded, setSectionLoaded] = useState(false);

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed && match && !sectionLoaded) {
			if (match.mediaSection) {
				setActiveSection(match.mediaSection);
			}
			setSectionLoaded(true);
		}
		return () => {
			subscribed = false;
		};
	}, [match, sectionLoaded, setActiveSection]);

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed) {
			const trailerTimeout = () => {
				setTimeout(() => {
					setTrailerLoaded(true);
					// playVideo()
				}, 1000);
			};
			trailerTimeout();
		}
		return () => {
			subscribed = false;
		};
	}, []);

	const vidOpts = {
		height: "390",
		width: "640",
		playerVars: {
			autoplay: 0,
			controls: 1,
			cc_load_policy: 0,
			fs: 1,
			iv_load_policy: 3,
			modestbranding: 1,
			rel: 0,
			showinfo: 0,
			playsinline: 1,
		},
	};
	const onVideoFullEnd = () => {
		videoFullRef.current.internalPlayer.stopVideo();
		setTimeout(() => {
			// setTrailerLoaded(false)
		}, 500);
	};
	const onVideoReady = () => {
		// if (videoFullRef.current)
		// videoFullRef.current.internalPlayer.playVideo()
	};

	return (
		<>
			<div
				className={
					"main__content col-12 col-md-8 d-flex ps-4 ps-md-5 pe-4 pe-md-0 flex-column align-items-start justify-content-center"
				}
				style={{
					position: "relative",
					zIndex: hasContent && !isMobile ? 1 : 3,
					marginTop:
						hasContent && !isMobile
							? "5rem"
							: !hasContent && !isMobile
								? "-10rem"
								: "0rem",
					paddingTop: !hasContent && isMobile ? "1rem" : 0,
					paddingBottom: !hasContent && isMobile ? "0" : 0,
				}}
			>
				{isMobile && hasContent ? (
					<div className={"w-100"}>
						<SidebarMeta
							titleName={titleName}
							socialFB={socialFB}
							socialTW={socialTW}
							socialIG={socialIG}
							reviewQuote={reviewQuote}
							reviewCredit={reviewCredit}
							indeeProjectKey={indeeProjectKey}
							setActiveProjectKey={setActiveProjectKey}
							isLoggedIn={isLoggedIn}
							setDisplayPinModal={setDisplayPinModal}
							setDisplayScreenerProjectList={
								setDisplayScreenerProjectList
							}
							setActiveTitleName={setActiveTitleName}
							hasAwards={hasAwards}
							sagIneligible={sagIneligible}
							availableOn={availableOn}
							indeeApiChecked={indeeApiChecked}
						/>
					</div>
				) : null}

				{trailerId && titleSlug === "is-it-cake" ? (
					<div
						id="container_trailer"
						className={
							"d-block mt-3 mb-3 mb-md-0 mt-md-0 pe-0 pe-md-4 titlepage__trailer--container w-100 " +
							(trailerLoaded ? "active" : "")
						}
					>
						<TitleMediaMP4
							mediaPosterUrl={
								"https://jwlfyc-public-us-east-1.s3.amazonaws.com/1742293859865-isitcakeholiday-trailer_1.jpg"
							}
							mediaUrl={
								"https://jwlfyc-public-us-east-1.s3.amazonaws.com/1742293844125-isitcakeholiday-trailer_1.mp4"
							}
						/>
					</div>
				) : trailerId ? (
					<div
						id="container_trailer"
						className={
							"d-block mt-3 mb-3 mb-md-0 mt-md-0 pe-0 pe-md-4 titlepage__trailer--container w-100 " +
							(trailerLoaded ? "active" : "")
						}
					>
						<div className="video__container">
							<YouTube
								id="trailer_video"
								className="videofull__video"
								ref={videoFullRef}
								onReady={onVideoReady}
								onEnd={onVideoFullEnd}
								videoId={trailerId}
								opts={vidOpts}
								style={{ zIndex: 5 }}
							/>
						</div>
					</div>
				) : null}

				{isMobile && !hasContent ? (
					<div className={"w-100"}>
						<SidebarMeta
							titleName={titleName}
							socialFB={socialFB}
							socialTW={socialTW}
							socialIG={socialIG}
							reviewQuote={reviewQuote}
							reviewCredit={reviewCredit}
							indeeProjectKey={indeeProjectKey}
							setActiveProjectKey={setActiveProjectKey}
							isLoggedIn={isLoggedIn}
							setDisplayPinModal={setDisplayPinModal}
							setDisplayScreenerProjectList={
								setDisplayScreenerProjectList
							}
							setActiveTitleName={setActiveTitleName}
							hasAwards={hasAwards}
							sagIneligible={sagIneligible}
							availableOn={availableOn}
							indeeApiChecked={indeeApiChecked}
						/>
					</div>
				) : null}

				{hasContent ? (
					<div className={"mt-5 mb-5 mb-md-0 w-100"}>
						<div
							className={
								"d-flex flex-column flex-md-row flex-md-wrap align-items-start justify-content-start"
							}
						>
							{hasEvents ? (
								<div
									id="container_events"
									className={"col-12 mb-5"}
								>
									<div
										className={
											"titledetail__feed-container"
										}
									>
										<div
											className={
												"titledetail__feed-title"
											}
										>
											<span className={"line"} />
											<span className={"title"}>
												Events
											</span>
											<span className={"line"} />
										</div>
										<div
											className={
												"titledetail__feed-item titledetail__event--container d-flex"
											}
										>
											{eventList
												? eventList.map((o, i) => {
														return (
															<EventExcerptTitlePage
																key={i}
																event_object={o}
															/>
														);
													})
												: null}
										</div>
									</div>
								</div>
							) : null}

							{hasPress &&
							(taglist_press || taglist_press_videos) ? (
								<div
									id="container_press"
									className={"col-12 mb-4"}
								>
									<MediaGrid
										titleSlug={titleSlug}
										categorySlug={categorySlug}
										mediaLists={[
											taglist_press,
											taglist_press_videos,
										]}
										mediaSection={"press"}
										gridTitle={"Press"}
									/>
								</div>
							) : null}

							{hasPresentations && taglist_presentations ? (
								<div
									id="container_design-presentations"
									className={"col-12 mb-4"}
								>
									<MediaGrid
										titleSlug={titleSlug}
										categorySlug={categorySlug}
										mediaLists={[taglist_presentations]}
										mediaSection={"design-presentations"}
										gridTitle={"Design Presentations"}
									/>
								</div>
							) : null}

							{hasVideos && (taglist_videos || fyseeVideoList) ? (
								<div
									id="container_videos"
									className={"col-12 mb-4 "}
								>
									<MediaGrid
										titleSlug={titleSlug}
										categorySlug={categorySlug}
										mediaLists={[
											taglist_videos,
											fyseeVideoList,
										]}
										mediaSection={"videos"}
										gridTitle={"Videos"}
									/>
								</div>
							) : null}

							{hasMediaRead && scripts ? (
								<div
									id="container_scripts"
									className={"col-12 mb-4 "}
								>
									<MediaGrid
										titleSlug={titleSlug}
										categorySlug={categorySlug}
										mediaLists={[scripts]}
										mediaSection={"scripts"}
										gridTitle={"Scripts"}
									/>
								</div>
							) : null}
						</div>
					</div>
				) : null}

				{hasAwards && competitions ? (
					<div
						id="container_fyc"
						className="d-flex d-md-none mb-5 pb-5 flex-md-fill flex-column w-100"
					>
						<div className={"titledetail__feed-container"}>
							<CompetitionsAwards
								show_id={titleId}
								slug={titleSlug}
								titleName={titleName}
								award_count={award_count}
								competitions={competitions}
							/>
						</div>
					</div>
				) : (
					""
				)}

				<div className={"w-100 d-block d-md-none"}>
					<SagIneligible show={hasAwards && sagIneligible} />
				</div>

				{/*</div>*/}
			</div>
		</>
	);
};

export default TitleDetailContent;
