import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const SidebarCategoryItem = ({
	categorySlug,
	showSlug,
	showTitle,
	showTitleTreatment,
}) => {
	const [titleTTImage, setTitleTTImage] = useState(null),
		[customLogo, setCustomLogo] = useState(false);

	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";
	const location = useLocation();

	const selectedType = location.pathname.includes("/pga")
		? "pga"
		: location.pathname.includes("/ves")
			? "ves"
			: "";

	useLayoutEffect(() => {
		let subscribed = true;
		if (subscribed && showSlug) {
			if (showSlug === "stranger-things") {
				setCustomLogo(true);
				setTitleTTImage(
					s3Url +
						"1649209671068-1649174262919-strangerthings-s4_tt_v1-bw.png",
				);
			} else if (showSlug === "big-mouth") {
				setCustomLogo(true);
				setTitleTTImage(s3Url + showTitleTreatment);
			} else if (showSlug === "spirit-rangers") {
				setCustomLogo(true);
				setTitleTTImage(
					s3Url + "1666614972075-spiritrangers-tt-bw.png",
				);
			} else if (showSlug === "agent-elvis") {
				setCustomLogo(true);
				setTitleTTImage(
					s3Url +
						"1678887103061-agent_elvis_s1_title_treatment_white.png",
				);
			} else if (showSlug === "sr") {
				setCustomLogo(true);
				setTitleTTImage(s3Url + "1678982666333-sr-tt-custom1.png");
			} else if (showSlug === "beef") {
				setCustomLogo(true);
				setTitleTTImage(s3Url + "1678983021428-beef-tt-custom1.png");
			} else if (showSlug === "is-it-cake") {
				setCustomLogo(true);
				setTitleTTImage(
					s3Url + "1678983128894-isitcake-tt-custom1.png",
				);
			} else if (showSlug === "squid-game") {
				setCustomLogo(true);
				setTitleTTImage(
					s3Url + "1729253619539-ENUS_SQG_TT_stacked_ATL_Grey.png",
				);
			} else {
				setCustomLogo(false);
				setTitleTTImage(s3Url + showTitleTreatment);
			}
		}
		return () => {
			subscribed = false;
		};
	}, [showSlug, showTitleTreatment]);

	return (
		<a
			href={`/${selectedType ? selectedType + "/" : ""}${categorySlug}/${showSlug}`}
			className="sidebar__list--flyout-item"
		>
			<img
				src={titleTTImage}
				className={
					"sidebar__list--flyout-image " +
					(customLogo ? "" : "white-overlay")
				}
				alt={showTitle}
			/>
		</a>
	);
};

export default SidebarCategoryItem;
