import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import FooterSmall from "../Footer/FooterSmall";
import $ from "jquery";
import { isMobile, isTablet } from "react-device-detect";
import {
	getLandingPageData,
	getPGAPageData,
	getVESPageData,
} from "../../utils/api/helpers/LandingPage";

const Home = () => {
	const [videoPlaying, setVideoPlaying] = useState(false),
		[backgroundVideo, setBackgroundVideo] = useState(null),
		[backgroundPoster, setBackgroundPoster] = useState(null),
		[titleTreatment, setTitleTreatment] = useState(null),
		[showGenreSlug, setShowGenreSlug] = useState(null),
		[showSlug, setShowSlug] = useState(null),
		[backgroundLoaded, setBackgroundLoaded] = useState(false),
		[categories, setCategories] = useState([]);

	const videoRef = useRef();
	const s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/";

	const location = useLocation();

	const selectedType = location.pathname.includes("/pga")
		? "pga"
		: location.pathname.includes("/ves")
			? "ves"
			: "";

	const buildGenreSlug = (genres) => {
		let genre = JSON.parse("[" + genres + "]")[0];
		if (genre.slug.includes("documentary")) {
			return "documentary-series-or-special";
		} else if (genre.slug.includes("variety")) {
			return "variety-programs";
		} else if (
			genre.slug.includes("reality") ||
			genre.slug.includes("competition")
		) {
			return "reality-series";
		} else {
			return genre.slug;
		}
	};

	useEffect(() => {
		let subscribed = true;

		if (subscribed && !backgroundLoaded) {
			if (location.pathname.includes("/pga")) {
				getPGAPageData().then((result) => {
					setCategories(result.data.GenreList);
					if (result.data.vidList.length) {
						let randIndex = Math.floor(
							Math.random() * result.data.vidList.length,
						);
						setBackgroundVideo(
							s3Url + result.data.vidList[randIndex].bgVideo,
						);
						setBackgroundPoster(
							s3Url + result.data.vidList[randIndex].thumbnail,
						);
						setTitleTreatment(
							s3Url +
								result.data.vidList[randIndex].titleTreatment,
						);
						setShowGenreSlug(
							buildGenreSlug(
								result.data.vidList[randIndex].genre,
							),
						);
						setShowSlug(result.data.vidList[randIndex].slug);
						setBackgroundLoaded(true);
					}
				});
			} else if (location.pathname.includes("/ves")) {
				getVESPageData().then((result) => {
					setCategories(result.data.GenreList);
					const vesVidList = [
						{
							title: "3 Body Problem",
							slug: "3-body-problem",
							bgVideo: "1729799815089-3bp-home.mp4",
							titleTreatment: "1711044209662-3BODYPROBLEM-TT.png",
							mediaSlug: "1729799815089-3bp-homemp4",
							thumbnail: "1729799828036-3bp-home.jpg",
							genre: [
								'{"name": "Drama Series", "slug": "drama-series"}',
							],
						},
						{
							title: "Ripley",
							slug: "ripley",
							bgVideo: "1712883005602-ripley_homebg.mp4",
							titleTreatment: "1728403088273-Riley_TT.png",
							mediaSlug: "1712883005602-ripley_homebgmp4",
							thumbnail: "1712883021428-ripley_homebg.jpg",
							genre: [
								'{"name": "Limited Series", "slug": "limited-series"}',
							],
						},
						{
							title: "Avatar: The Last Airbender",
							slug: "avatar-the-last-airbender",
							bgVideo: "1729800198989-avatar-home.mp4",
							titleTreatment:
								"1711050522885-Avatar-TLA-S1_TT_gold.png",
							mediaSlug: "1729800198989-avatar-homemp4",
							thumbnail: "1729800209093-avatar-home.jpg",
							genre: [
								'{"name": "Drama Series", "slug": "drama-series"}',
							],
						},
					];
					let randIndex = Math.floor(
						Math.random() * vesVidList.length,
					);
					setBackgroundVideo(s3Url + vesVidList[randIndex].bgVideo);
					setBackgroundPoster(
						s3Url + vesVidList[randIndex].thumbnail,
					);
					setTitleTreatment(
						s3Url + vesVidList[randIndex].titleTreatment,
					);
					setShowGenreSlug(
						buildGenreSlug(vesVidList[randIndex].genre),
					);
					setShowSlug(vesVidList[randIndex].slug);
					setBackgroundLoaded(true);
				});
			} else {
				getLandingPageData().then((result) => {
					setCategories(result.data.GenreList);
					if (result.data.vidList.length) {
						let randIndex = Math.floor(
							Math.random() * result.data.vidList.length,
						);
						setBackgroundVideo(
							s3Url + result.data.vidList[randIndex].bgVideo,
						);
						setBackgroundPoster(
							s3Url + result.data.vidList[randIndex].thumbnail,
						);
						setTitleTreatment(
							s3Url +
								result.data.vidList[randIndex].titleTreatment,
						);
						setShowGenreSlug(
							buildGenreSlug(
								result.data.vidList[randIndex].genre,
							),
						);
						setShowSlug(result.data.vidList[randIndex].slug);
						setBackgroundLoaded(true);
					}
				});
			}
		}
		return () => (subscribed = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgroundLoaded, location.pathname]);

	useEffect(() => {
		let subscribed = true;
		if (subscribed && videoRef && backgroundLoaded) {
			setTimeout(() => {
				if (videoRef.current) videoRef.current.play();
				setVideoPlaying(true);
			}, 200);
		}
		return () => (subscribed = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoRef, backgroundLoaded]);

	const pauseVideo = () => {
		if (videoRef.current) videoRef.current.pause();
		setVideoPlaying(false);
	};
	const playVideo = () => {
		if (videoRef.current) videoRef.current.play();
		setVideoPlaying(true);
	};

	useEffect(() => {
		let subscribed = true;
		if (subscribed && backgroundLoaded) {
			$(document).on(
				"mouseenter",
				".sidebar__container--list li",
				function (e) {
					$(".sidebar__container--list li a").removeClass("active");
					$(".sidebar__container--ctalist").addClass("active");
					$(".homepage__cta--container").addClass("active");
					$(".main__background").addClass("active");
					$(".main__background--info").addClass("active");
					$(".fysee__background").addClass("active");
					$(".fysee__content").addClass("active");
					$(e.target).addClass("active");
				},
			);
			$(document).on(
				"mouseleave",
				".sidebar__container--list li",
				function (e) {
					$(".sidebar__container--ctalist").removeClass("active");
					$(".homepage__cta--container").removeClass("active");
					$(".main__background").removeClass("active");
					$(".main__background--info").removeClass("active");
					$(".fysee__background").removeClass("active");
					$(".fysee__content").removeClass("active");
				},
			);
			$(document).on(
				"mouseenter",
				".sidebar__list--flyout-item",
				function () {
					$(".main__background").addClass("active");
					$(".main__background--info").addClass("active");
					$(".fysee__background").addClass("active");
					$(".fysee__content").addClass("active");
				},
			);
			$(document).on(
				"mouseleave",
				".sidebar__list--flyout-item",
				function () {
					$(".main__background").removeClass("active");
					$(".main__background--info").removeClass("active");
					$(".fysee__background").removeClass("active");
					$(".fysee__content").removeClass("active");
				},
			);

			$(document).ready(function () {
				$("#mainBackgroundVideo").on("timeupdate", function (event) {
					onTrackedVideoFrame(this.currentTime, this.duration);
				});
			});

			function onTrackedVideoFrame(currentTime, duration) {
				let percentage = (currentTime / duration) * 100;
				$("#videoBar1").css({ width: percentage + "%" });
			}
		}
		return () => {
			subscribed = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgroundLoaded]);

	return (
		<>
			<div className="content__container d-flex flex-fill ">
				<div className="width_ctrl row no-gutters flex-fill">
					<Sidebar
						categories={categories}
						videoPlaying={videoPlaying}
						playVideo={playVideo}
						pauseVideo={pauseVideo}
					/>
				</div>
			</div>

			<div
				className="d-none d-md-block main__background--controls"
				onClick={() => {
					videoPlaying ? pauseVideo() : playVideo();
				}}
			>
				{videoPlaying ? (
					<svg
						viewBox="0 0 24 24"
						width="48"
						height="48"
						stroke="currentColor"
						strokeWidth="2"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						className=""
					>
						<circle cx="12" cy="12" r="10"></circle>
						<line x1="10" y1="15" x2="10" y2="9"></line>
						<line x1="14" y1="15" x2="14" y2="9"></line>
					</svg>
				) : (
					<svg
						viewBox="0 0 24 24"
						width="48"
						height="48"
						stroke="currentColor"
						strokeWidth="2"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						className=""
					>
						<circle cx="12" cy="12" r="10"></circle>
						<polygon points="10 8 16 12 10 16 10 8"></polygon>
					</svg>
				)}
				<div className="main__background--controls-tip arrowbox__grey">
					{videoPlaying ? "Pause Video" : "Play Video"}
				</div>
			</div>

			{backgroundLoaded ? (
				<>
					{!isMobile && !isTablet ? (
						<div className="main__background--info d-none d-md-flex flex-column align-items-center justify-content-center">
							<img
								src={titleTreatment}
								alt="Netflix"
								className="mb-4 main__background--info-tt"
							/>
							<a
								href={`/${selectedType ? selectedType : ""}${selectedType ? "/" : ""}${showGenreSlug}/${showSlug}`}
							>
								<button className="button__box button__icon--right">
									<span className="button__text">
										Learn More{" "}
									</span>
									<span
										className="icon ps-2"
										style={{ marginTop: "-2px" }}
									>
										<svg
											viewBox="0 0 24 24"
											width="14"
											height="14"
											stroke="currentColor"
											strokeWidth="2"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											className="css-i6dzq1"
										>
											<polyline points="9 18 15 12 9 6"></polyline>
										</svg>
									</span>
								</button>
							</a>
						</div>
					) : null}

					<div className="main__background no-default">
						<video
							ref={videoRef}
							poster={backgroundPoster}
							playsInline
							muted
							loop
							id="mainBackgroundVideo"
						>
							<source src={backgroundVideo}></source>
						</video>
					</div>

					<div className="video-indicator">
						<div id="videoBar1" className="video-bar">
							<div className="time"></div>
							<div className="duration"></div>
							<div className="percentage"></div>
						</div>
					</div>
				</>
			) : null}

			<div className="main__gradient main__gradient-home" />

			<FooterSmall />
		</>
	);
};

export default Home;
