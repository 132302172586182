import React from "react";

const HeaderNavMobile = ({
	setShowSearch,
	isLoggedIn,
	displayPinModal,
	setDisplayPinModal,
	setDisplayScreenerProjectList,
	setActiveProjectKey,
	activeTitleName,
	setActiveTitleName,
	indeeApiChecked,
	mobileNavOpen,
	setMobileNavOpen,
}) => {
	return (
		<div
			className={
				"subnav_mobile-container " + (mobileNavOpen ? "active" : "")
			}
		>
			<div className={"d-flex justify-content-end p-3"}>
				<div
					style={{ cursor: "pointer" }}
					className={""}
					onClick={() => {
						setMobileNavOpen(false);
					}}
				>
					<svg
						viewBox="0 0 24 24"
						width="24"
						height="24"
						stroke="currentColor"
						strokeWidth="2"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
						className="css-i6dzq1"
					>
						<line x1="18" y1="6" x2="6" y2="18"></line>
						<line x1="6" y1="6" x2="18" y2="18"></line>
					</svg>
				</div>
			</div>
			<div
				className={
					"d-flex flex-column justify-content-center align-items-center p-4"
				}
				style={{ gap: "2rem" }}
			>
				<div
					className=" p-0 "
					onClick={() => {
						setShowSearch(true);
						setMobileNavOpen(false);
					}}
				>
					<span className=" ">SEARCH</span>
				</div>

				<a
					href={"/"}
					onClick={(e) => {
						e.preventDefault();
					}}
					className={
						"screenerLink d-flex" +
						(!indeeApiChecked ? "screenerLinkLoading" : "")
					}
					style={{
						opacity: indeeApiChecked ? 1 : 0.3,
						pointerEvents: indeeApiChecked ? "all" : "none",
					}}
				>
					<div
						className="p-0 "
						onClick={() => {
							setMobileNavOpen(false);
							if (isLoggedIn) {
								// console.log('header screeners', activeTitleName)
								setActiveProjectKey(null);
								setActiveTitleName(null);
								setDisplayScreenerProjectList(true);
							} else {
								setDisplayPinModal(true);
							}
						}}
					>
						<span className="button__text ">SCREENERS</span>
					</div>
				</a>

				<a href={"/events"} className="d-flex ">
					<div className="p-0 ">
						<span className="button__text ">EVENTS</span>
					</div>
				</a>
			</div>
		</div>
	);
};

export default HeaderNavMobile;
