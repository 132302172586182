import React from "react";
import SidebarCategory from "./SidebarCategory";

const Desktop = ({ categories, videoPlaying, playVideo, pauseVideo }) => {
	return (
		<div className="sidebar__container--inner d-flex flex-column ">
			<ul className="pt-0 sidebar__container--list flex-fill d-flex flex-column justify-content-center">
				{categories ? (
					<>
						{categories.map((c, i) => {
							return <SidebarCategory key={i} category={c} />;
						})}
					</>
				) : (
					""
				)}
			</ul>
		</div>
	);
};

export default Desktop;
